import { AlertModal } from '../../..'
import { observer } from 'mobx-react-lite'
import { Icons } from '../../../misc/Icons'
import { store } from '../../../stores/Store'
import PanelBody from '../../layout/PanelBody'
import PanelHeader from '../../layout/PanelHeader'
import { Button, IconButton } from '@mui/material'
import ViewQuestionnaire from './ViewQuestionnaire'
import QuestionnaireTable from './QuestionnaireTable'
import ErrorBoundary from '../../../misc/ErrorBoundary'
import CreateQuestionnaireForm from './CreateQuestionnaireForm'
import QuestionnaireImportForm from './QuestionnaireImportForm'
import AccountQuestionnaireEnableButton from '../../form/admin/AccountQuestionnaireEnableButton'


export default observer(function QuestionnaireTool () {        

    const { getQuestionnaires, searchString, setSearchString  } = store.QuestionnaireStore

    var userAccountUid = store.AppStore.sessionInfo.userAccount?.uid
    var account = store.NodeStore.accounts.find(a => a.uid === userAccountUid)
    var questionnairesEnabled = !!account?.advisorUid

    return (
        <ErrorBoundary>
            <div className="panel panel-dark">
                <PanelHeader>
                    <div className="header">
                        <h2>Questionnaires</h2>
                        
                        {questionnairesEnabled ? (
                            <>
                                <div style={{display: "flex", gap: "5px", alignItems: "center"}}>
                                    <div className="form-search form-search-dark">
                                        <input 
                                            autoFocus 
                                            value={searchString}
                                            placeholder="Search"
                                            spellCheck="false"
                                            onChange={(e) => {
                                                setSearchString(e.target.value)
                                            }} />
                                    </div>
                                    <Button
                                        color="info"
                                        className="btn btn-sm"
                                        onClick={() => getQuestionnaires()}>
                                        <Icons.Refresh /> Refresh
                                    </Button>
                                </div>
                                
                                <Button
                                    className="btn btn-sm"
                                    variant="contained"
                                    onClick={() => AlertModal({body: <CreateQuestionnaireForm />, width: "550px"})}>
                                    Create Questionnaire
                                </Button>

                                <Button
                                    className="btn btn-sm"
                                    variant="contained"
                                    onClick={() => AlertModal({body: <QuestionnaireImportForm />, width: "550px"})}>
                                    Import Questionnaire
                                </Button>
                            </>
                        ) : null}
                    </div>
                    
                    <IconButton style={{marginLeft: "auto"}} size="small" onClick={() => store.ToolStore.setTool(null)}><Icons.X /></IconButton>
                </PanelHeader>
                
                <PanelBody>
                    {questionnairesEnabled ? (
                        <QuestionnaireTable onRowClick={(uid) => AlertModal({width: "700px", body: <ViewQuestionnaire uid={uid} />})} />
                    ) : (
                        <>
                            <p>Questionnaires are not enabled for your account.</p>
                            <p>To enable this feature, click the button below.</p>
                            <p><AccountQuestionnaireEnableButton account={account} /></p>
                        </>
                    )}
                </PanelBody>
            </div>
        </ErrorBoundary>
    )
})