import * as Yup from 'yup'
import { useFormik } from "formik"
import { AlertModal } from "../../.."
import { Button } from "@mui/material"
import { Icons } from "../../../misc/Icons"
import { observer } from "mobx-react-lite"
import Field from "../../form/fields/Field"
import { store } from "../../../stores/Store"
import { CaseCreateForm } from "../../form/case/CaseForms"
import CreateEnquiryForm from "../../form/enquiry/CreateEnquiryForm"
import LoadQuestionnaire from '../../form/enquiry/LoadQuestionnaire'
import { NewSponsorClientAccountForm } from '../../form/admin/AccountForms'
import { FolderCreateForm } from '../../form/folder/FolderForms'
import EnquiryCreateForm from '../../form/enquiry/create_form/EnquiryCreateForm'


export default observer(function FolderPanelCta () {

    var {folderNode, loading} = store.NodeStore
    var empyCase = !folderNode?.childrenObjects?.filter((node) => !node.typeName.includes("Sponsor"))?.length

    if (store.AppStore.sessionState) return <></>

    if (!folderNode && !loading) {
        return (
            <Button
                className="btn btn-sm"
                variant="contained"
                onClick={() => AlertModal({body: <NewSponsorClientAccountForm />})}>
                Create new Sponsor
            </Button>
        )
    }

    if (["Aora.Platform.Data.AccountNode", "Aora.Nationality.Data.SponsorAccountNode", "Aora.Platform.Data.FolderNode"].includes(folderNode?.typeName) && !loading) {

        var guidance = "We advise you to use the family name of the subject in the name of the case."

        if (folderNode.typeName === "Aora.Nationality.Data.SponsorAccountNode") {
            guidance = `This case will be associated with ${folderNode.label}, Skilled Worker enquiries will be available.`
        } else if (folderNode.typeName === "Aora.Platform.Data.AccountNode") { 
            guidance = "This case won't be associated with a Sponsor Client so skilled worker enquiries will not be possible."
        }

        return (
            <div style={{display: "flex", gap: "8px", marginTop: "10px"}}>
                <Button 
                    className="btn btn-sm" 
                    variant="contained" onClick={() => {
                        AlertModal({body: (
                            <FolderCreateForm 
                                parentUid={folderNode.uid} 
                                onSuccess={() => {
                                    store.AlertStore.Close()
                                    store.NodeStore.refreshFolderNode()
                            }} />
                        )})
                    }}>
                    Create Folder
                </Button>
                <Button 
                    className="btn btn-sm" 
                    variant="contained" onClick={() => {
                        AlertModal({body: (
                            <CaseCreateForm 
                                parentUid={folderNode.uid} 
                                guidance={guidance} 
                                onSuccess={(node) => {
                                    store.AlertStore.Close()
                                    store.NodeStore.navigateNode(node.uid)
                            }} />
                        )})
                    }}>
                    Create Case
                </Button>
            </div>
        )
    }

    if (["Aora.Platform.Data.CaseNode"].includes(folderNode?.typeName) && !loading) {

        var subjectOptions = folderNode.childrenObjects.filter(c => c.typeName.includes("Person")).map(p => {
            return {value: p.uid, label: p.label}
        })

        return (
            <>
                {empyCase ? (
                    <div style={{display: "flex", alignItems: "center", gap: "4px"}}>
                        <Icons.Info style={{fontSize: "12px", color: "grey"}} />
                        <small>Create a new person to get started</small>
                    </div>
                ) : null}

                <div style={{display: "flex", flexDirection: "column", gap: "8px", marginTop: "10px"}}>
                    {/* <Button
                        variant="contained" 
                        className="btn btn-sm" 
                        onClick={() => AlertModal({width: "700px", body: <LoadQuestionnaire caseUid={folderNode.uid} />})}
                        disabled={store.QuestionStore.initialisationForm === "CREATE_PERSON"}>
                        Load Questionnaire
                    </Button> */}
                    
                    <div style={{display: "flex", gap: "8px"}}>
                        <Button
                            variant="contained" 
                            className="btn btn-sm" 
                            onClick={() => store.QuestionStore.initForm("CREATE_PERSON")}
                            disabled={store.QuestionStore.initialisationForm === "CREATE_PERSON"}>
                            Create Person
                        </Button>

                        {/* {!empyCase ? (
                            <Button
                                variant="contained" 
                                className="btn btn-sm" 
                                onClick={() => AlertModal({body: <SelectSubjectForm options={subjectOptions} />, size: "auto"})}
                                disabled={store.QuestionStore.initialisationForm === "CREATE_PERSON"}>
                                Create Enquiry
                            </Button>
                        ) : null} */}

                        <Button
                            variant="contained" 
                            className="btn btn-sm" 
                            onClick={() => AlertModal({body: <EnquiryCreateForm caseUid={folderNode.uid} />, size: "auto"})}
                            disabled={store.QuestionStore.initialisationForm === "CREATE_PERSON"}>
                            Create Enquiry
                        </Button>
                    </div>
                </div>
            </>
        )
    }

    return <></>
})


function SelectSubjectForm ({options}) {

    var formik = useFormik({
        initialValues: {
            subjectUid: ""
        },
        validationSchema: Yup.object({
            subjectUid: Yup.string().required("Please choose a subject")
        }),
        onSubmit: (values) => {
            store.AlertStore.Close()
            AlertModal({body: <CreateEnquiryForm entityUid={values.subjectUid} hideStepsSection={window.innerWidth < 700} />, size: "auto"})
        }
    })

    return (
        <form style={{minWidth: "320px"}} className="form" onSubmit={formik.handleSubmit}>
            <h2>Create enquiry</h2>

            <div className="form-fields">
                <Field.Select 
                    formik
                    name="subjectUid"
                    options={options} 
                    onChange={formik.handleChange}
                    label="Select the subject of your enquiry" 
                    value={formik.values.subjectUid} />
            </div>
            
            <div className="form-buttons">
                <Button
                    className="btn btn-sm" 
                    onClick={() => store.AlertStore.Close()}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    className="btn btn-sm" >
                    Next
                </Button>
            </div>
        </form>
    )
}