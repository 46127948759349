import React from "react"
import { AlertModal } from "../../.."
import { Link } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { store } from "../../../stores/Store"
import Account from "../../../models/Account"
import TruncText from "../../../misc/TruncText"
import InlineMenu from "../../../misc/InlineMenu"
import { Icons, TypeIcon } from "../../../misc/Icons"
import { Button, CircularProgress, MenuItem, MenuList } from "@mui/material"
import { AccountCreateForm, NewSponsorClientAccountForm } from "../../form/admin/AccountForms"


interface AccountSelectProps {
    value: string;
    accounts: Account[];
    handleSelect: Function;
    color?: "primary" | "secondary" | "info";
    size?: "xs" | "sm" | "md" | "lg";
}


export default observer(function AccountSelect ({value, accounts, handleSelect, color = "primary", size = "xs"} : AccountSelectProps) {

    const currentAccount = accounts.find(account => account.uid === value)
    const buttonColor = value === store.AppStore.sessionInfo.userAccount.uid
        ? color
        : currentAccount ? "secondary" : "info"

    if (!accounts.length) return <CircularProgress color="info" style={{width: "10px", height: "10px"}} />

    return (
        <div className="account-select">
            <InlineMenu closeOnClick={true} title="Accounts" opener={(
                <Button
                    className={`btn btn-${size}`}
                    variant="contained"
                    color={buttonColor}>
                    <Icons.ArrowRight style={{transform: "rotateZ(90deg)", fontSize: "20px"}} />
                </Button>
            )}>
                <div style={{padding: "0 14px 14px 14px"}}>
                    <AccountSelectMenu value={value} accounts={accounts} handleSelect={handleSelect} />
                </div>
            </InlineMenu>

            <Button
                className={`btn btn-${size} btn-lowercase`}
                variant="contained"
                color={buttonColor}
                onClick={() => handleSelect(currentAccount?.uid)}>
                {currentAccount ? currentAccount?.label : <CircularProgress color="info" style={{width: "10px", height: "10px"}} />}
            </Button>
        </div>
    )
})


export function AccountSelectMenu ({value, accounts, handleSelect}) {

    const account = accounts.find(account => account.uid === value)
    var mainAccount = store.NodeStore.accounts.find(account => account.uid === store.AppStore.sessionInfo?.userAccount?.uid)
    const inOtherAccount = value !== mainAccount?.uid

    return (
        <div className="account-select-menu">
            <Button onClick={() => handleSelect(mainAccount?.uid)} className="btn btn-lg btn-lowercase">
                <h4 style={{textDecoration: "underline"}}>
                    {mainAccount?.label} {inOtherAccount && <Icons.Open />}
                </h4>
            </Button>

            {!inOtherAccount ? <small>You are currently working within your account.</small> : (
                <>
                    <small>You are currently working within <b>{account?.label}'s</b> account.</small>
                    <small className="link"><Link to={"/app/main"}>Back to your account</Link></small>
                </>
            )}

            <AccountsList value={value} accounts={accounts} handleSelect={handleSelect} />

            <div className="button-group">
                <Button
                    className="btn btn-sm"
                    variant="contained"
                    onClick={(e) => AlertModal({body: <NewSponsorClientAccountForm />})}>
                    Create new account
                </Button>

                {store.AppStore.checkJwtRole("admin_account") ? (
                    <Button
                        className="btn btn-sm"
                        variant="contained"
                        onClick={(e) => AlertModal({body: <AccountCreateForm initialValues={{accountType: "customer"}} onSuccess={() => {
                            store.NodeStore.refreshAccounts()
                            store.AlertStore.Close()
                        }} />})}>
                        Create new customer
                    </Button>
                ) : null}
            </div>
            
            <small style={{marginTop: "5px"}}>Sponsor or Non-Sponsor client account</small>
        </div>
    )
}


function AccountsList ({value, accounts, handleSelect}) {

    var customers = accounts.filter(account => account.typeName === "Aora.Platform.Data.AccountNode" && account.accountType === "customer")
    var clients = accounts.filter(account => account.typeName === "Aora.Platform.Data.AccountNode" && account.accountType === "client")
    var sponsorClients = accounts.filter(account => account.typeName === "Aora.Nationality.Data.SponsorAccountNode")

    var clientLabel = store.NodeStore.getDisplayType({typeName: "Aora.Platform.Data.AccountNode"}, true)
    var sponsorLabel = store.NodeStore.getDisplayType({typeName: "Aora.Nationality.Data.SponsorAccountNode"}, true)

    if (!clients.length && !sponsorClients.length) return <small>You haven't set up any client accounts yet.<br/><br/></small>

    if (store.AppStore.checkJwtRole("admin_account")) {

        var customerClients = clients.filter((client) => client.parent?.uid === store.AppStore.sessionInfo.userAccount?.uid)
        var customerSponsorClients = sponsorClients.filter((client) => client.parent?.uid === store.AppStore.sessionInfo.userAccount?.uid)

        return (
            <div className="account-list">

                <AccountListGroup groupHeader={sponsorLabel} accounts={customerSponsorClients} value={value} handleSelect={handleSelect} />
                <AccountListGroup groupHeader={clientLabel} accounts={customerClients} value={value} handleSelect={handleSelect} />

                <h4 style={{margin: "10px 0"}}>AORA Customers</h4>

                {customers.map((customer, i) => {
                    var customerClients = clients.filter((client) => client.parent?.uid === customer.uid)
                    var customerSponsorClients = sponsorClients.filter((client) => client.parent?.uid === customer.uid)
                    return (
                        <div className="account-list-customer-group">
                            <MenuItem key={i} style={{padding: "4px 4px"}} selected={customer.uid === value} onClick={() => handleSelect(customer.uid)}>
                                <TruncText text={customer.label} style={{fontWeight: "500", textDecoration: "underline"}} />
                            </MenuItem>

                            <AccountListGroup groupHeader={sponsorLabel} accounts={customerSponsorClients} value={value} handleSelect={handleSelect} />
                            <AccountListGroup groupHeader={clientLabel} accounts={customerClients} value={value} handleSelect={handleSelect} />
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div className="account-list">
            <AccountListGroup groupHeader={clientLabel} accounts={clients} value={value} handleSelect={handleSelect} />
            <AccountListGroup groupHeader={sponsorLabel} accounts={sponsorClients} value={value} handleSelect={handleSelect} />
        </div>
    )
}


function AccountListGroup ({groupHeader, value, accounts, handleSelect}) {

    const [minimised, setMinimised] = React.useState(false)

    if (!accounts?.length) return <></>

    return (
        <>
            <div className="menu-divider">
                <Button className="btn btn-sm btn-lowercase" onClick={(e) => {
                    e.stopPropagation()
                    setMinimised(!minimised)
                }}>
                    <b style={{color: "black"}}>{groupHeader}</b>
                    <Icons.ArrowRight style={{fontSize: "16px", padding: "0", transform: minimised ? "rotate(0)" : "rotate(90deg)"}} />
                </Button>
            </div>

            {!minimised ? (
                <MenuList>
                    {accounts.map((account, i) => (
                        <MenuItem key={i} selected={account.uid === value} onClick={() => handleSelect(account.uid)}>
                            <TypeIcon node={account} />
                            <TruncText text={account.label} />
                        </MenuItem>
                    ))}
                </MenuList>
            ) : <div style={{padding: "3px"}}></div>}
        </>
    )
}