import { observer } from "mobx-react-lite";
import ErrorBoundary from "../../../../../misc/ErrorBoundary";
import { store } from "../../../../../stores/Store";
import Automation from "./Automation";
import AutomationCta from "./AutomationCta";
import React from "react";
import Field from "../../../../form/fields/Field";
import { CircularProgress } from "@mui/material";


export default observer(function AutomationSection () {

    const {enquiry} = store.EnquiryStore
    const [showOldVersions, setShowOldVersions] = React.useState(false)

    var allAutomators = enquiry?.automations?.map((aNode) => {
        return JSON.parse(aNode.automatorJson)
    })
    
    const isNewest = (automator) => {
        var newerVersion = allAutomators.find((a) => {
            var run_at1 = new Date(automator.run_at)
            var run_at2 = new Date(a.run_at)
            var firstName1 = automator.profile["subject_first_name"]
            var firstName2 = a.profile["subject_first_name"]
            
            if (automator.id !== a.id && automator.automation_id === a.automation_id) {
                if (automator.automation_id.includes("CHILD")) {
                    // console.log(firstName1, firstName2, automator.automation_id, firstName1 === firstName2 && run_at1 < run_at2)
                    return firstName1 === firstName2 && run_at1 < run_at2
                } else {
                    return run_at1 < run_at2
                }
            }
            return false
        })

        return !newerVersion
    }

    const newAutomators = allAutomators.filter(a => isNewest(a))
    const automatorsToShow = showOldVersions ? allAutomators : newAutomators
    const runningAutomator = store.AutomationStore.runningAutomation?.automator

    return (
        <ErrorBoundary>
            <div className="detail-section">
                <div className="section-header">
                    <div className="header">
                        <h4>Automations</h4>

                        {allAutomators.length !== newAutomators.length ? (
                            <Field.Checkbox 
                                label="Show old versions" 
                                className="field-checkbox-sm"
                                value={showOldVersions} 
                                warning={false} 
                                onChange={() => setShowOldVersions(!showOldVersions)} />
                        ) : null}
                    </div>
                </div>

                <AutomationCta />
                
                {runningAutomator ? (
                    <>
                        <div className="section-subheader">
                            <h5>Running automations <CircularProgress style={{ width: "8px", height: "8px", margin: "0 0 0 5px" }} /></h5>
                        </div>
                        <Automation label={runningAutomator.label} automator={runningAutomator} />
                    </>
                ) : null}

                {automatorsToShow?.length ? (
                    <>
                        <div className="section-subheader">
                            <h5>Previous automations</h5>
                        </div>
                        {automatorsToShow.reverse().map((automator, i) => <Automation key={i} label={automator.label} automator={automator} /> )}
                    </>
                ) : null}
            </div>
        </ErrorBoundary>
    )
})