import { observer } from "mobx-react-lite"
import { Icons } from "../../../../../misc/Icons"
import { Theme } from "../../../../../misc/Theme"
import { Button } from "@mui/material"
import { AlertModal, DisplayValue } from "../../../../.."
import AutomationDetail from "./AutomationDetail"
import Help from "../../../../../misc/Help"
import { format } from "date-fns"
import TruncText from "../../../../../misc/TruncText"
import FormAutomationChart from "../../../../../misc/Charts"
import { Automator } from "../../../../../models/Automator"
import { store } from "../../../../../stores/Store"


export interface AutomationProps {
    label?: string;
    automator: Automator;
}


export default observer(function Automation ({label, automator}: AutomationProps) {

    const {statusColor, statusDescription} = store.AutomationStore

    var currentPageNumber = (automator.current_page + 1)
    const pagesCompletePercent = (currentPageNumber / automator.total_pages) * 100
    // const finishingPagePercentage = (automator.finishing_page_index / automator.total_pages) * 100

    var color = statusColor(automator.status)
    var description = statusDescription(automator)
    var status = DisplayValue("automation_status", automator.status)

    return (
        <div className="automation">

            <Help content={(
                <div className="automation-page-bar">
                    <div className="indicator" style={{width: `${pagesCompletePercent}%`}}></div>
                    {/* <div className="page-limit-indicator" style={{marginLeft: `${finishingPagePercentage-2}%`}}></div> */}
                </div>
            )}>
                <small>{Math.floor(pagesCompletePercent)}%</small>
            </Help>

            <div className="section-table" style={{marginBottom: "0"}}>
                <div className="section-row" style={{display: "grid", gridTemplateColumns: "22px auto 85px 30px 90px 80px", borderBottom: "0.5px solid rgba(0, 0, 0, 0.089)", padding: "5px"}}>
                    <div className="section-cell">
                        <Icons.Automation color="primary" />
                    </div>
                    
                    <TruncText text={label} />
                    
                    <span>
                        <Help content={format(new Date(automator.run_at), "dd/MM/yyyy")}>
                            <h4>Automated at</h4>
                            {format(new Date(automator.run_at), "dd/MM/yyyy HH:mm")}
                        </Help>
                    </span>

                    <FormAutomationChart
                        labels={[]}
                        chartData={[
                            automator.genuine_value_count,
                            automator.guessed_value_count,
                            automator.false_value_count
                        ]}
                        colors={[
                            Theme.palette.success.main,
                            Theme.palette.secondary.main,
                            Theme.palette.error.main,
                        ]} />
                    
                    <Help content={(
                        <b style={{color: color, fontSize: "14px"}}>{status}</b>
                    )}>
                        {description}
                    </Help>
                    
                    <Button 
                        color="success"
                        variant="contained"
                        style={{padding: "4px 4px"}}
                        onClick={() => AlertModal({id: "automation-details", body: <AutomationDetail label={label} automator={automator} />, width: "1100px"})}>
                        View details
                    </Button>
                </div>
            </div>
        </div>
    )
})