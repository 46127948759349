import React from "react"
import { useFormik } from "formik"
import Field from "../../fields/Field"
import { Button } from "@mui/material"
import { Icons } from "../../../../misc/Icons"


export default function EnquirySettingsForm ({onSubmit, settingNodes}) {
    console.log(settingNodes)
    var initialSettingsValues = {}
    var settings = {}

    settingNodes.forEach(setting => {
        // Build initial values for formik
        if (setting.datatype === "B") {
            initialSettingsValues[setting.code] = setting.value === "true"    
        } else {
            initialSettingsValues[setting.code] = setting.value !== null
                ? setting.value
                : setting.availableValues[0].value
        }
        // Add setting to settings object
        if (setting.category in settings) {
            settings[setting.category].push(setting)
        } else {
            settings[setting.category] = [setting]
        }
    })

    const formik = useFormik({
        initialValues: initialSettingsValues,

        onSubmit: (values) => {
            var newSettingNodes = settingNodes?.map(setting => {
                if (setting.code in values) {
                    setting.value = values[setting.code].toString()
                }
                return setting
            })
            onSubmit(newSettingNodes)
        }
    })

    const SettingField = ({setting}) => {

        if (setting.datatype === "B") {
            return (
                <Field.Checkbox
                    formik
                    name={setting.code}
                    label={setting.label}
                    value={!!formik.values[setting.code]}
                    warning={false}
                    onChange={formik.handleChange} />
            )
        }
        if (setting.datatype === "I") {
            return (
                <Field.Select
                    formik
                    name={setting.code}
                    label={setting.label}
                    value={formik.values[setting.code]}
                    // warning={false}
                    options={setting.availableValues.map(option => {
                        return {
                            value: option.value,
                            label: option.label === null ? option.value : option.label
                        }
                    })}
                    onChange={(e) => formik.handleChange(e)} />
            )
        }
        if (setting.datatype === "S") {
            return (
                <Field.Select
                    formik
                    name={setting.code}
                    label={setting.label}
                    value={formik.values[setting.code]}
                    // warning={false}
                    options={setting.availableValues.map(option => {
                        return {
                            value: option.value,
                            label: option.label === null ? option.value : option.label
                        }
                    })}
                    onChange={(e) => formik.handleChange(e)} />
            )
        }
        return <h5>{setting.label}</h5>
    }

    return (
        <div className="form">
            {Object.keys(settings).reverse().map((category, i) => (
                <React.Fragment key={i}>
                    <h4 style={{textTransform: "capitalize"}}>{category.toLocaleLowerCase()} settings</h4>
                    <div style={{display: "grid", gap: "8px", padding: "0 10px"}}>
                        {settings[category].map((setting, i) => <SettingField key={i} setting={setting} /> )}
                    </div>
                </React.Fragment>
            ))}

            <div className="form-buttons">
                <Button
                    className="btn btn-sm"
                    variant="contained"
                    color="success"
                    onClick={() => formik.submitForm()}>
                    <Icons.ChevronLeft /> Confirm settings
                </Button>
            </div>
        </div>
    )
}