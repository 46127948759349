import Ajax from "../Ajax"
import { store } from "./Store"
import { Alert } from ".."
import { NodeStore } from "./NodeStore"
import { ToolStore } from "./ToolStore"
import { ReportStore } from "./ReportStore"
import { EnquiryStore } from "./EnquiryStore"
import { QuestionStore } from "./QuestionStore"
import { dict_key } from "../misc/DisplayDicts"
import { SessionInfo } from "../models/Interfaces"
import { StateChange } from "../models/StateChange"
import { makeAutoObservable, reaction } from "mobx"
import { ConnectionStore } from "./ConnectionStore"
import { AutomationStore } from "./AutomationStore"
import { QuestionnaireStore } from "./QuestionnaireStore"


export class AppStore {

    appLoaded = false
    currentPanel: string = "FOLDER"
    sessionState: string = ""
    sessionInfo: SessionInfo | null = null
    displayDictionaries: any;
    token: string | null = window.localStorage.getItem("aora_jwt")

    navigate: (string: string) => void
    location: Location;
    // reader = new OnDemandLiveRegion() // Screen reader

    constructor() {

        makeAutoObservable(this)

        // This is run when this.token changes so we can control the token
        // in browser storage via this property

        reaction(() => this.token, token => {

            token
                ? window.localStorage.setItem("aora_jwt", token)
                : window.localStorage.removeItem("aora_jwt")

        })
    }

    resetApp = () => {

        this.setAppLoaded(false)
        this.navigate("/")
        this.setToken(null)
        this.setPageTitle("AORA")

        store.AutomationStore.reset()
        store.NodeStore = new NodeStore()
        store.ToolStore = new ToolStore()
        store.AlertStore.alertModals = []
        // store.AlertStore = new AlertStore()
        store.ReportStore = new ReportStore()
        store.EnquiryStore = new EnquiryStore()
        store.QuestionStore = new QuestionStore()
        store.ConnectionStore = new ConnectionStore()
        store.AutomationStore = new AutomationStore()
        store.QuestionnaireStore = new QuestionnaireStore()

        this.currentPanel = "FOLDER"
        this.sessionState = ""
        this.sessionInfo = null
        this.setAppLoaded()
    }

    get isLoggedIn () {
        return !!this.token
    }

    get stateAction () {

        var value = this.sessionState?.split(" ").length > 1
            ? this.sessionState?.split(" ")[0]
            : null

        if (value?.split("_").length > 0) {
            value = value?.split("_")[0]
        }

        return value
    }

    get stateSubject () {
        var value = this.sessionState?.split(" ").length > 1 ? this.sessionState?.split(" ")[1] : ""
        value.replace("Node", "")
        return value
    }

    init = async (token = null) => {

        if (token) {
            this.setToken(token)
        }

        await Ajax.Session.Info().then((response) => {
            this.sessionInfo = response.data
        })

        await Ajax.Session.Language().then((response) => {
            this.displayDictionaries = {...response.data, dict_key: dict_key}
        })

        await store.NodeStore.refreshAccounts()

        await store.AutomationStore.getOptions()
    }

    setPageTitle = (title: string) => {
        document.body.focus()
        document.body.blur()
        document.title = title
        // this.reader.say(title)
    }

    setSessionState = (stateChange: StateChange) => {

        console.log(`"${stateChange.previousState}" -> "${stateChange.newState}"`, stateChange)
        this.sessionState = stateChange.newState

        // Neutral state
        if (!!stateChange.newState === false) {
            store.QuestionStore.informationRequest = null
            store.QuestionStore.highlightUid = null
            store.QuestionStore.locatorPath = []
        }

        // Editing starting
        if (stateChange.newState?.toLowerCase().includes("editing")) {
            this.currentPanel = "QUESTION"
            // if (store.NodeStore.leafNode === null && window.innerWidth > 600 && this.stateSubject.) {
            //     setTimeout(() => {
            //         store.NodeStore.navigateNode(this.stateSubject)
            //     }, 1000)
            // }
        }

        // Creating ending
        if (stateChange.previousState?.toLowerCase().includes("creating")) {
            store.NodeStore.refreshFolderNode()
        }

        // Editing ending
        if (stateChange.previousState?.toLowerCase().includes("editing")) {
            store.NodeStore.refreshFolderNode()
            store.NodeStore.refreshLeafNode()
        }

        // Enquiry starting
        if (stateChange.newState?.toLowerCase().includes("running")) {
            // store.QuestionnaireStore.alert?.dismiss()
        }

        // Enquiry ending
        if (stateChange.previousState?.toLowerCase().includes("running")) {
            var enquiryUid = stateChange.previousState?.split(" ")[1]
            store.NodeStore.navigateNode(enquiryUid)
            store.NodeStore.refreshFolderNode()
        }
    }

    loginOAuth = async (oauthRequestBody: any) =>
        await Ajax.Session.LoginOAuth(oauthRequestBody).then(async (response) => {
            store.AppStore.setToken(response.data.token)
            await this.init()
            this.navigate("/app/main")
            store.AppStore.setAppLoaded()

        }).catch((response) => {
            Alert({ message: response.data.detail })
            this.navigate("/")
        })

    logout = async () =>
        await Ajax.Session.Logout().finally(() => this.resetApp())

    passwordResetRequest = () => Alert({message: "Password reset requested not implemented"})

    setToken = (token: string | null) => {
        this.token = token
    }

    setAppLoaded = (loaded = true) => {
        this.appLoaded = loaded
    }

    checkJwtRole = (value) => {

        var roles = this.getJwtClaim("role")

        if (!Array.isArray(roles)) {
            return false
        }

        return roles.includes(value)
    }

    checkJwtRoles = (values: string[], all: boolean = true) => {

        var roles = this.getJwtClaim("role")

        if (!Array.isArray(roles)) {
            return false
        }

        var matchedValues = values.filter(value => roles.includes(value))

        if ((all && matchedValues.length === values.length) || (!all && matchedValues.length)) {
            return true
        }

        return false
    }

    getJwtClaim = (name: string) => {

        const token = window.localStorage.getItem("aora_jwt")

        if (token) {
            var claims = this.decodeJWT()

            if (name in claims) {
                return claims[name]
            }
        }
    }

    decodeJWT = () => {

        var base64Url = this.token?.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    getCookie = (cname: string) => {

        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    setCookie = (cname: string, cvalue: any, exdays: any) => {

        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    getURLPeram = (url: string, name: string) => {

        if (url && url.split("?").length > 1) {
            var query_string = url.split("?")[1]
            var perams = query_string.split("&")

            for (let i = 0; i < perams.length; i++) {
                if (perams[i].split("=")[0] === name && perams[i].split("=").length > 1) {
                    return perams[i].split("=")[1]
                }
            }
        }
        return null
    }

    DisplayDictionary = (dictionaryName: string, asValueAndLabel: boolean = false, order: "asc" | "desc" = "desc") => {

        var displayDict = this.displayDictionaries[dictionaryName]

        // Always return a dict
        if (!displayDict) {
            return asValueAndLabel ? [] : {}
        }

        // Return in format [ {value: "...", label: "..."} ]
        if (asValueAndLabel) {
            displayDict = Object.keys(displayDict).map((key, i) => {
                return {value: key, label: displayDict[key]}
            })
        }

        // Return as dict
        return displayDict
    }

    DisplayValue = (dictionaryName: string, keyName: string) => {

        var displayDict = this.displayDictionaries[dictionaryName]

        if (displayDict && Object.keys(displayDict).includes(keyName)) {
            return displayDict[keyName]
        }

        // Fallback to return keyName
        return keyName
    }
}