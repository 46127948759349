import { format, isValid } from "date-fns"
import { AlertModal } from "../../../../.."
import TruncText from "../../../../../misc/TruncText"
import { store } from "../../../../../stores/Store"
import ViewQuestionnaire from "../../../questionnaire_mgmt/ViewQuestionnaire"
import Help from "../../../../../misc/Help"
import React from "react"
import { Icons } from "../../../../../misc/Icons"


export default function EnquiryDataSourcesSection () {
    
    const {enquiry} = store.EnquiryStore

    var sources_grouped = []

    enquiry.dataSources?.forEach((source) => {
        var existing_item = sources_grouped.find((o) => source.sourceUid === o.sourceUid)
        if (!existing_item) {
            sources_grouped.push({
                ...source,
                insertedAt: undefined,
                insertedBy: undefined,
                usage: [
                    {date: source.insertedAt, user: source.insertedBy}
                ]
            })
        } else {
            existing_item.usage.push({date: source.insertedAt, user: source.insertedBy})
        }
    })

    return (
        <div className="detail-section">

            <div className="section-header">
                <div className="header-content">
                    <h4>Data origins</h4>
                    {/* {!sessionState ? <Button className="btn btn-xs" onClick={() => UpdateForm()}>Edit</Button> : null} */}
                </div>
            </div>

            {sources_grouped?.length ? (
                <>
                    {sources_grouped?.map((source, i) => (
                        <div key={i} className="section-table" style={{marginBottom: "10px"}}>
                            <div className="section-row" style={{display: "grid", gridTemplateColumns: "22px auto 150px 100px"}}>
                                <div className="section-cell">
                                    <Icons.Questionnaire color="primary" />
                                </div>

                                {source.description ? (
                                    <div className="section-cell link" onClick={() => AlertModal({body: <ViewQuestionnaire uid={source.sourceUid} />, width: "700px"})}>
                                        <TruncText text={source.description} />
                                    </div>
                                ) : null}
                                
                                {source.label ? (
                                    <div className="section-cell">
                                        <TruncText text={source.label} />
                                    </div>
                                ) : null}

                                <div className="section-cell">
                                    <Help content={`Used ${source.usage.length} time${source.usage.length !== 1 ? "s" : ""}`}>
                                        <Usage source={source} />
                                    </Help>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            ) : (
                <div className="section-header section-subheader">
                    <h5>No entries</h5>
                </div>
            )}
        </div>
    )
}


function Usage ({source}) {


    var usage = source.usage?.sort((a,b) => {
        return a.date?.localeCompare(b.date)
    })

    return (
        <div>
            <h3>Data from this source was used {source.usage.length} time{source.usage.length !== 1 ? "s" : ""}</h3>
            <div style={{margin: "10px", display: "grid", gridTemplateColumns: "140px auto"}}>
                <small><b>Time</b></small>
                <small><b>User</b></small>
                {usage.map((usage, i) => {
                    var date = new Date(usage.date)
                    var formattedDate = isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : "Invalid date"
                    return (
                        <React.Fragment key={i}>
                            <small>{formattedDate}</small>
                            <small>{usage.user ? usage.user : "Unknown user"}</small>
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    )
}