import React from "react"
import * as Yup from 'yup'
import { format } from "date-fns"
import { useFormik } from "formik"
import Field from "../../fields/Field"
import { store } from "../../../../stores/Store"
import { Button, CircularProgress } from "@mui/material"
import { DisplayDictionary } from "../../../.."
import Ajax from "../../../../Ajax"


export default function EnquirySubjectFormSection ({
    hidden,
    questionnaireUidArray,
    onCancel = () => {},
    onSubmit = (data) => {},
}) {

    var subjectOptions = store.NodeStore.folderNode.childrenObjects.filter(c => c.typeName.includes("Person")).map(p => {
        return {value: p.uid, label: p.label}
    })
    var defaultSubjectAction: "new" | "existing" | null = !subjectOptions?.length ? "new" : null

    const [subjectAction, setSubjectAction] = React.useState<"new" | "existing" | null>(defaultSubjectAction)
    const [questionnaireData, setQuestionnaireData] = React.useState(null)

    React.useEffect(() => {
        if (!hidden) {
            Ajax.Enquiry.GetNewSubjectDetails(questionnaireUidArray).then((response) => {
                setQuestionnaireData(response.data)
                if (questionnaireUidArray?.length) {
                    setSubjectAction("new")
                }
            })
        }
    }, [questionnaireUidArray])

    const handleCancel = () => {
        setSubjectAction(defaultSubjectAction)
        setQuestionnaireData(null)
        onCancel()
    }

    if (hidden) return <></>

    if (questionnaireUidArray?.length && !questionnaireData) {
        return (
            <Wrapper>
                <CircularProgress style={{margin: "100px auto"}} />
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <div>
                {subjectOptions?.length ? (
                    <Field.Radio 
                        formik
                        label="Who is this enquiry about?"
                        value={subjectAction}
                        onChange={(e) => setSubjectAction(e.target.value)}
                        options={[
                            {value: "existing", label: "A person already declared within this case"},
                            {value: "new", label: "A new person, new details must be entered"},
                        ]} />
                ) : (
                    <p>Please enter the basic details of the person who this enquiry is for.</p>
                )}

                {subjectAction === "existing" ? (
                    <SelectExistingSubject 
                        subjectOptions={subjectOptions} 
                        onSubmit={(subjectUid) => onSubmit({subjectUid: subjectUid})} 
                        onCancel={() => handleCancel()} />
                ) : null}

                {subjectAction === "new" ? (
                    <NewSubjectForm 
                        questionnaireData={questionnaireData}
                        onSubmit={(subjectInfo) => onSubmit({subjectInfo: subjectInfo})} 
                        onCancel={() => handleCancel()} />
                ) : null}

                {!subjectAction ? (
                    <div className="form-buttons">
                        <Button className="btn btn-sm" onClick={() => onCancel()}>
                            Back
                        </Button>
                    </div>
                ) : null}
            </div>
        </Wrapper>
    )
}

const Wrapper = ({children}) => {
    return (
        <div className="form">
            <h2>Create Enquiry<span style={{color: "grey", fontWeight: 400}}> - Subject Details</span></h2>
            {children}
        </div>
    )
}

function SelectExistingSubject ({subjectOptions, onSubmit, onCancel}) {

    const [subjectUid, setSubjectUid] = React.useState(null)

    return (
        <>
            <Field.Radio 
                formik
                name="subjectUid"
                options={subjectOptions} 
                value={subjectUid}
                onChange={(e) => setSubjectUid(e.target.value)}
                label="Select the subject of your enquiry"  />  

            <div className="form-buttons">
                <Button 
                    className="btn btn-sm" 
                    onClick={() => onCancel()}>
                    Back
                </Button>
                
                <Button 
                    className="btn btn-sm" 
                    variant="contained" 
                    color="success" 
                    disabled={!subjectUid}
                    onClick={() => onSubmit(subjectUid)}>
                    Submit
                </Button>
            </div>
        </>
    )
}


function NewSubjectForm ({questionnaireData, onSubmit, onCancel}) {

    const formik = useFormik({

        initialValues: {
            subject_first_name:         questionnaireData?.subject_first_name ? questionnaireData.subject_first_name : "",
            subject_family_name:        questionnaireData?.subject_family_name ? questionnaireData.subject_family_name : "",
            subject_gender:             questionnaireData?.subject_gender ? questionnaireData.subject_gender : null,
            subject_date_of_birth:      questionnaireData?.subject_gender ? format(new Date(questionnaireData.subject_date_of_birth), "yyyy-MM-dd") : null,
            subject_country_of_birth:   questionnaireData?.subject_country_of_birth ? questionnaireData.subject_country_of_birth : null,
            subject_place_of_birth:     questionnaireData?.subject_place_of_birth ? questionnaireData.subject_place_of_birth : null,
        },

        validationSchema: Yup.object({
            subject_first_name:     Yup.string().required("This field is required"),
            subject_family_name:    Yup.string().required("This field is required"),
            subject_gender:         Yup.string().required("This field is required"),
            subject_date_of_birth:  Yup.date()
                .required("This field is required")
                .min(new Date("1950-01-01"), `After 1950-01-01`)
                .max(new Date(), `Must be before today`)
                .typeError("Please choose an interest date"),
            subject_country_of_birth:   Yup.string().required("This field is required"),
            subject_place_of_birth:     Yup.string().required("This field is required"),
        }),

        onSubmit: (values, actions) => {
            onSubmit(values)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div style={{maxWidth: "100%", maxHeight: "60vh", overflow: "auto"}}>
                <div style={{maxWidth: "300px"}}>
                    <Field.Text 
                        formik
                        name="subject_first_name"
                        label="First name"
                        value={formik.values.subject_first_name}
                        warning={formik.errors.subject_first_name}
                        // disabled={!!questionnaireData?.subject_first_name}
                        onChange={formik.handleChange} />
                    
                    <Field.Text 
                        formik
                        name="subject_family_name"
                        label="Last name"
                        value={formik.values.subject_family_name}
                        warning={formik.errors.subject_family_name}
                        // disabled={!!questionnaireData?.subject_family_name}
                        onChange={formik.handleChange} />
    
                    <Field.Date 
                        formik
                        name="subject_date_of_birth"
                        label="Date of birth"
                        value={formik.values.subject_date_of_birth}
                        warning={formik.errors.subject_date_of_birth}
                        // disabled={!!questionnaireData?.subject_date_of_birth}
                        onChange={(e) => formik.setFieldValue("subject_date_of_birth", e.target.value)} />
                    
                    <Field.Radio 
                        formik
                        name="subject_gender"
                        label="Gender"
                        value={formik.values.subject_gender}
                        warning={formik.errors.subject_gender}
                        // disabled={!!questionnaireData?.subject_gender}
                        onChange={formik.handleChange}
                        options={DisplayDictionary("sex", true)} />
                    
                    <Field.MinimisedLargeListSelect
                        formik
                        name="subject_country_of_birth"
                        label="Country of birth"
                        multiple={false}
                        maxValueCount={1}
                        value={formik.values.subject_country_of_birth}
                        warning={formik.errors.subject_country_of_birth}
                        // disabled={!!questionnaireData?.subject_country_of_birth}
                        onChange={formik.handleChange}
                        options={DisplayDictionary("countries", true)} />
                    
                    <Field.Text 
                        formik
                        name="subject_place_of_birth"
                        label="Place of birth"
                        value={formik.values.subject_place_of_birth}
                        warning={formik.errors.subject_place_of_birth}
                        // disabled={!!questionnaireData?.subject_place_of_birth}
                        onChange={formik.handleChange} />
                </div>
            </div>

            <div className="form-buttons">
                <Button className="btn btn-sm" onClick={() => onCancel()}>
                    Back
                </Button>
                
                <Button 
                    className="btn btn-sm" 
                    variant="contained" 
                    color="success"
                    type="submit">
                    Submit
                </Button>
            </div>
        </form>
    )
}