import React from "react"
import QuestionnaireSelectField from "./QuestionnaireSelectField"
import QuestionnaireSelectMultipleField from "./QuestionnaireSelectMulitpleField"


export default function QuestionnaireSelectFormSection ({
    formId,
    value,
    guidance = null,
    onSubmit = (value) => {}
}) {

    const [questionnaireUidArray, setQuestionnaireUidArray] = React.useState([])

    return (
        <form 
            id={formId} 
            className="form-fields" 
            onSubmit={(e) => {
                e.preventDefault()
                onSubmit(questionnaireUidArray)
            }}>
            
            {guidance ? <p>{guidance}</p> : null}
            
            <QuestionnaireSelectMultipleField
                value={questionnaireUidArray} 
                onChange={(value) => setQuestionnaireUidArray(value)} />
        </form>
    )
}