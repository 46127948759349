import { FormLabel } from "@mui/material";
import { Icons } from "../../../misc/Icons";
import { InputFieldProps } from "../../../models/Interfaces";


export interface FileMultipleFieldProps extends InputFieldProps {
    id?: string;
    name?: string;
    value?: File[];
    onChange?: Function;
}


export default function FileMultipleField ({
    name,
    value = [],
    label = "",
    onChange = () => {},
    warning = "",
    helpText = "",
    formik = false,
    disabled = false,
    autoFocus = false,
    id = Math.random().toString(),
}: FileMultipleFieldProps) {

    const callOnChange = (allFiles) => {
        var onChangeEventProto = {target: {name: name, value: allFiles}}
        formik ? onChange(onChangeEventProto) : onChange(onChangeEventProto.target)
    }

    const appendFiles = (newFiles) => {
        callOnChange([...value, ...newFiles])
    }

    const removeFile = (file) => {
        callOnChange(value.filter(f => !(f.name === file.name && f.size === file.size)))
    }

    const handleChange = (e) => 
        appendFiles(e.target.files)

    const pasteFromClipboard = (e) => 
        appendFiles(getFileFromClipboard(e))
    
    const getFileFromClipboard = (e) => {
        
        const items = [...(e.clipboardData || e.originalEvent.clipboardData).items];
        var cbFiles = []
        
        items.forEach((item) => 
            item.kind === 'file' && cbFiles.push(item.getAsFile()))

        return cbFiles
    }

    return (
        <div className="form-field field-file">

            {label ? <FormLabel className="field-file-label">{label}</FormLabel> : null}
            
            <div style={{display: "flex", alignItems: "center"}}>
                
                <label htmlFor={id} tabIndex={1} className="select-files-icon">
                    <Icons.UploadFile />
                </label>

                <input 
                    onPaste={(e) => pasteFromClipboard(e)} 
                    placeholder="or Ctrl+V to paste" 
                    value="" 
                    className="file-paste-input" />

                <input 
                    id={id}                    
                    hidden
                    name={name}
                    type="file" 
                    multiple={true} 
                    onChange={(e) => handleChange(e)}
                />

            </div>

            <div className="file-list">
                {value.length
                    ? value.map(file => (
                        <small>
                            <Icons.X onClick={() => removeFile(file)} />
                            <span className="file-name">{file.name}</span>
                            <span className="file-size">{(file.size / 1000000).toFixed(2)}MB</span>
                        </small>
                    )) 
                    : null //<small>No files selected</small>
                }
            </div>
            
            {warning !== false && typeof warning === "string" ? <span className="warning">{warning}</span> : null}

            {helpText ? <span className="help-text">{helpText}</span> : null}
        </div>
    )
}