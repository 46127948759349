import { AlertModal } from "../../../..";
import { Button } from "@mui/material";
import Logo from "../../../../misc/Logo";
import { Icons } from "../../../../misc/Icons";
import Panel, { PanelChildProps } from "../../../layout/Panel";
import AccountSelect, { AccountSelectMenu } from "../AccountSelect";
import { store } from "../../../../stores/Store";
import { observer } from "mobx-react-lite";
import EnquirySWVSetupForm from "../../../form/enquiry/EnquirySWVSetupForm";


export default function PlaceholderDetail () {
    return (
        <Panel index={3} className="detail" width="100%" hidden={window.innerWidth < 700}>
            <PlaceholderDetailContent />
        </Panel>
    )
}


const PlaceholderDetailContent = observer((props: PanelChildProps) => {
    return (
        <div className="placeholder-detail">
            <div className="placeholder-detail-content">
                <div>
                    <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                        <Logo width={60} color="#aaaaaa" type="block" />
                        <p>Guidance</p>
                    </div>
                    <hr style={{borderColor: "#cacaca"}} />
                </div>

                <p>AORA uses folders to organise your cases.</p>

                <div style={{marginLeft: "20px"}}>
                    <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                        <Icons.Home style={{fontSize: "16px"}} />
                        <p>Home</p>
                    </div>

                    {/* <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                        <Icons.Subdirectory style={{fontSize: "16px", color: "#bbbbbb", margin: "-5px 0 0 5px"}} />
                        <Icons.Account style={{fontSize: "16px"}} />
                        <p>Legal advisor (Nationality)</p>
                    </div>

                    <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                        <Icons.Subdirectory style={{fontSize: "16px", color: "#bbbbbb", margin: "-5px 0 0 5px"}} />
                        <Icons.Account style={{fontSize: "16px"}} />
                        <p>Private clients (Nationality)</p>
                    </div> */}
                    
                    <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                        <Icons.Subdirectory style={{fontSize: "16px", color: "#bbbbbb", margin: "-5px 0 0 5px"}} />
                        <Icons.Account style={{fontSize: "16px"}} />
                        <p>Sponsors (Skilled worker visa)</p>
                    </div>
                    
                    <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                        <Icons.Subdirectory style={{fontSize: "16px", color: "#bbbbbb", margin: "-5px 0 0 32px"}} />
                        <Icons.Folder style={{fontSize: "16px"}} />
                        <p>Folders</p>
                    </div>
                    
                    <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                        <Icons.Subdirectory style={{fontSize: "16px", color: "#bbbbbb", margin: "-5px 0 0 60px"}} />
                        <Icons.Case style={{fontSize: "16px"}} />
                        <p>Cases</p>
                    </div>
                    
                    <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                        <Icons.Subdirectory style={{fontSize: "16px", color: "#bbbbbb", margin: "-5px 0 0 86px"}} />
                        <Icons.Enquiry style={{fontSize: "16px"}} />
                        <p>Enquiries</p>
                    </div>

                    {/* <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                        <Icons.Subdirectory style={{fontSize: "16px", color: "#bbbbbb", margin: "-5px 0 0 5px"}} />
                        <Icons.Account style={{fontSize: "16px"}} />
                        <p>Private Clients</p>
                    </div>
                    
                    <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                        <Icons.Subdirectory style={{fontSize: "16px", color: "#bbbbbb", margin: "-5px 0 0 32px"}} />
                        <Icons.Case style={{fontSize: "16px"}} />
                        <p>Cases</p>
                    </div>
                    
                    <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                        <Icons.Subdirectory style={{fontSize: "16px", color: "#bbbbbb", margin: "-5px 0 0 60px"}} />
                        <Icons.Enquiry style={{fontSize: "16px"}} />
                        <p>Enquiries</p>
                    </div> */}
                </div>

                {/* <p>There is an <b>important distinction</b> between Sponsors and Private Clients.</p>


                <ul style={{marginTop: 0}}>
                    <li>Skilled Worker cases <b>MUST</b> be created under a Sponsor.</li>
                    <li>Nationality cases may be created under Private Clients or Sponsors.</li>
                    <li>Further options for a folder can be accessed by clicking the ellipsis (<Icons.More style={{fontSize: "12px"}} />).</li>
                </ul> */}

                <p>Further options for a folder can be accessed by clicking the ellipsis (<Icons.More style={{fontSize: "12px"}} />).</p>

                {/* {!store.AppStore.checkJwtRoles(["client_account", "sponsor_account"], false) ? (
                    <Button
                        color="primary"
                        variant="contained"
                        className="btn btn-md"
                        onClick={() => AlertModal({title: "Open a new case", body: <EnquirySWVSetupForm />})}>
                        Create Skilled Worker Visa case
                    </Button>
                ) : null} */}

                {/* <div style={{marginTop: "20px"}}>
                    <p>Help and Resources</p>
                    <hr style={{borderColor: "#cacaca"}} />
                </div> */}

                {/* <a target="_blank" rel="noreferrer" href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_ZGM0OTUyMDEtYWZjZS00ZTI3LThmMDctMDRkYzkxNzdlNzc0%40thread.v2%2F0%3Fcontext%3D%257B%2522Tid%2522%3A%2522f3ac26bd-42f9-4f2c-878b-9e61cda924d6%2522%2C%2522Oid%2522%3A%2522fe37d0b8-3f52-4d4e-a96e-c579bc4e4f17%2522%257D%26anon%3Dtrue&type=meetup-join&deeplinkId=30775937-3f59-4c5c-aebd-456314da5b3e&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true">
                    <Button className="btn btn-lg btn-lowercase" color="info" variant="contained"><b>Call the support hotline on Teams</b><Icons.Open /></Button>
                </a> */}

                <a href="https://aora-law.navattic.com/6hw07ye" target="_blank" rel="noreferrer">
                    <Button className="btn btn-md" variant="contained"><b>Interactive tutorial</b><Icons.Run /></Button>
                </a>
            </div>
        </div>
    )
})