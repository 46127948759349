import React from "react"
import * as Yup from 'yup'
import Ajax from "../../../Ajax"
import { useFormik } from "formik"
import { observer } from "mobx-react-lite"
import Field from "../../form/fields/Field"
import { Alert, AlertModal } from "../../.."
import { store } from "../../../stores/Store"
import ViewQuestionnaire from "./ViewQuestionnaire"
import { Button, LinearProgress } from "@mui/material"


export default observer(function CreateQuestionnaireForm () {

    const { templates, getTemplates, getQuestionnaires } = store.QuestionnaireStore

    React.useEffect(() => {
        if (!templates?.length) {
            getTemplates()
        }
    }, [])

    const formik = useFormik({

        initialValues: {
            displayName: "",
            respondentEmail: "",
            templateKey: ""
        },

        validationSchema: Yup.object({
            displayName: Yup.string().matches(/^[ ,.@!$%#';:?()[\]~_+=À-Ö\p{Sc}\p{So}\p{Mn}\p{P}\p{Z}À-ÿ\w\n]*$/u, "You have used some illegal characters").required("This field is required"),
            respondentEmail: Yup.string().matches(
                /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/,
                "A valid email is required"
            ).required("This field is required"),
            templateKey: Yup.string().required("This field is required"),
        }),

        onSubmit: async (values, actions) => {
            values.respondentEmail = values.respondentEmail.toLowerCase()
            await Ajax.Questionnaire.Create(values.templateKey, values.respondentEmail, values.displayName).then((response) => {
                getQuestionnaires()
                store.AlertStore.Close()
                AlertModal({width: "700px", body: <ViewQuestionnaire uid={response.data?.uid} />})
            }).catch((response) => {
                Alert({message: response.data?.detail, color: "error"})
            })
        }
    })

    var templateOptions = Array.isArray(templates) && templates.length ? (
        [...templates].sort((a, b) => a.displayName.localeCompare(b.displayName)).filter(t => t.active).map((template) => {
            return {value: template.key, label: template.displayName}
        }) 
    ) : []

    return (
        <form className="form" onSubmit={formik.handleSubmit}>
            <div className="form-header">
                <div>
                    <h2>Create Questionnaire</h2>
                    <p>Complete this form to create a new questionnaire for your client.</p>
                    <p>Once the questionnaire is created, you will be provided with a URL which you can send to the intended respondent.</p>
                </div>
            </div>

            <div className="form-fields" style={{maxWidth: "270px"}}>
                <Field.Text
                    formik
                    name="displayName"
                    label="Reference/label"
                    helpText="This can be the name of the applicant or job. The applicant won't see this."
                    onChange={formik.handleChange}
                    value={formik.values.displayName}
                    warning={formik.submitCount && formik.errors.displayName ? formik.errors.displayName : false} />

                <Field.Text
                    formik
                    name="respondentEmail"
                    label="Respondent contact email"
                    helpText="This is for your own record, we won't contact or share this email with anyone."
                    onChange={formik.handleChange}
                    value={formik.values.respondentEmail}
                    warning={formik.submitCount && formik.errors.respondentEmail ? formik.errors.respondentEmail : false} />
                
                <Field.Select
                    formik
                    label="Select a questionnaire template"
                    name="templateKey"
                    disabled={!templates}
                    onChange={formik.handleChange}
                    value={formik.values.templateKey}
                    warning={formik.submitCount && formik.errors.templateKey ? formik.errors.templateKey : ""}
                    options={templateOptions}
                />
            </div>

            <div className="form-buttons">
                <Button className="btn btn-sm" onClick={() => store.AlertStore.Close()}>Cancel</Button>
                <Button className="btn btn-sm" type="submit" variant="contained" disabled={formik.isSubmitting}>Create</Button>
            </div>
        </form>
    )
})