import QuestionnaireFilterForm from "./QuestionnaireFilterForm"
import QuestionnaireTable from "../../views/questionnaire_mgmt/QuestionnaireTable"

// Returns list of questionnaires

export default function QuestionnaireSelectMultipleField ({
    value,
    highlightedOptions = [],
    onRowClick = (uid: string) => {},
    onChange = (uids: string[]) => {}
}) {

    const toggle = (uid) => {
        var selected = !!value.find(qu => qu === uid)
        var newList = selected ? value.filter(u => u !== uid) : [...value, uid]  
        onChange(newList)
    }

    return (
        <div style={{display: "grid", gap: "10px"}}>
            <p>The order of your selections will decide how they're merged. For example, if there are conflicting values in questionnaire 1 and questionnaire 2, the value from questionnaire 1 will take precedence.</p>
            
            <QuestionnaireFilterForm />

            <QuestionnaireTable 
                size="sm" 
                width="400px" 
                height="300px" 
                selectedOptions={value}
                highlightedOptions={highlightedOptions}
                onCheckboxClick={(questionnaireUid) => toggle(questionnaireUid)} 
                onRowClick={(questionnaireUid) => onRowClick(questionnaireUid)}/>
        </div>
    )
}