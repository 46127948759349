import { Button, FormLabel } from "@mui/material";
import { Icons } from "../../../misc/Icons";
import { InputFieldProps } from "../../../models/Interfaces";
import TruncText from "../../../misc/TruncText";


export interface FileFieldProps extends InputFieldProps {
    id?: string;
    name?: string;
    value?: File;
    onChange?: Function;
}


export default function FieldFile ({
    name,
    value = null,
    label = "",
    onChange = () => {},
    warning = "",
    helpText = "",
    formik = false,
    disabled = false,
    autoFocus = false,
    id = Math.random().toString(),
}: FileFieldProps) {

    const callOnChange = (file) => {
        var onChangeEventProto = {target: {name: name, value: file}}
        formik ? onChange(onChangeEventProto) : onChange(onChangeEventProto.target)
    }

    const pasteFromClipboard = (e) => {
        callOnChange(getFileFromClipboard(e))
    }
    
    const getFileFromClipboard = (e) => {
        var file = null
        
        const items = [...(e.clipboardData || e.originalEvent.clipboardData).items];
        
        if (items.length && items[0].kind === "file") {
            file = items[0].getAsFile()
        }
        return file
    }

    return (
        <div className={disabled ? "form-field field-file disabled" : "form-field field-file"}>

            {label ? <FormLabel className="field-file-label">{label}</FormLabel> : null}
            
            <div style={{display: "flex", alignItems: "start", gap: "3px"}}>
                
                <label htmlFor={id} className="select-files-icon">
                    <Icons.UploadFile />
                </label>

                <input 
                    id={id}                    
                    hidden
                    name={name}
                    type="file" 
                    multiple={false} 
                    onChange={(e) => {
                        if (e.target.files?.length) {
                            callOnChange(e.target.files[0])
                        }
                    }}
                />

                {value ? (
                    <small style={{width: "100%", display: "flex", alignItems: "center", gap: "3px"}}>
                        <span style={{maxWidth: "190px", marginRight: "auto"}}>
                            <TruncText text={value.name} />
                        </span>
                        <span className="file-size">{(value.size / 1000).toFixed(1)}kb</span>
                        <Button style={{padding: 0}}>
                            <Icons.X onClick={() => callOnChange(null)} />
                        </Button>
                    </small>
                ) : (
                    <input 
                        onPaste={(e) => pasteFromClipboard(e)} 
                        placeholder="or Ctrl+V to paste" 
                        value="" 
                        className="file-paste-input" />
                )}
            </div>
            
            {warning !== false && typeof warning === "string" ? <span className="warning">{warning}</span> : null}

            {helpText ? <span className="help-text">{helpText}</span> : null}
        </div>
    )
}