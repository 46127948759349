import React from "react"
import Ajax from "../../../Ajax"
import { Alert } from "../../.."
import { Button } from "@mui/material"
import QuestionnaireSelectMultipleField from "../questionnaire/QuestionnaireSelectMulitpleField"
import QuestionnaireMergeFormSection from "../questionnaire/QuestionnaireMergeFormSection"
import { store } from "../../../stores/Store"
import QuestionnaireDetails from "../../views/questionnaire_mgmt/QuestionnaireDetails"
import { Icons } from "../../../misc/Icons"


export default function AutomationQuestionnaireSelectFormSection ({
    automationOption,
    hidden = true,
    onCancel = () => {},
    onSubmit = (values) => {}
}) {

    const [loading, setLoading] = React.useState(false)
    const [questionnaireUids, setQuestionnaireUids] = React.useState([])
    const [previewQuestionnaire, setPreviewQuestionnaire] = React.useState(false)
    const [merge, setMerge] = React.useState(false)
    
    if (hidden) return <></>

    const enquiryUid = store.EnquiryStore.enquiry.uid
    const qualifyingRule = store.EnquiryStore.getQualifyingRule()
    const dependantEnquiryProvisions = ["UK.D.33.2", "UK.D.33.3"]
    const dependantEnquiry = dependantEnquiryProvisions.includes(qualifyingRule)
    const dependantForm = automationOption.id.includes("CHILD") || automationOption.id.includes("PARTNER")
    const subjectPrefix = dependantForm && !dependantEnquiry ? "subject_supporter" : "subject"

    const preview = async (uid) => {
        setLoading(true)
        
        await Ajax.Questionnaire.Get(uid).then((response) => {
            setPreviewQuestionnaire(response.data)
        }).catch(() => {
            Alert({message: "Failed to get questionnaire"})
        })

        setLoading(false)
    }

    if (merge) {
        return (
            <QuestionnaireMergeFormSection 
                enquiryUid={enquiryUid} 
                subjectPrefix={subjectPrefix} 
                questionnaireUids={questionnaireUids}
                onCancel={() => setMerge(null)}
                onSubmit={(values) => onSubmit(values)} />
        )
    }

    return (
        <div className={loading ? "form disabled" : "form"}>

            {previewQuestionnaire ? (
                <>
                    <QuestionnaireDetails 
                        height="260px"
                        questionnaire={previewQuestionnaire} />

                    <Button 
                        className="btn btn-sm" 
                        variant="contained" 
                        color="success"
                        onClick={() => setPreviewQuestionnaire(null)}>
                        <Icons.ChevronLeft /> Exit preview
                    </Button>
                </>
            ) : null}

            <div style={{display: !!previewQuestionnaire ? "none" : "block"}}>
                <QuestionnaireSelectMultipleField 
                    value={questionnaireUids} 
                    onRowClick={(uid) => preview(uid)}
                    onChange={(value) => setQuestionnaireUids(value)} />

                <div className="header">
                    <Button 
                        className="btn btn-sm" 
                        onClick={() => onCancel()}>
                        Cancel
                    </Button>

                    <Button
                        color="success"
                        variant="contained"
                        className="btn btn-sm"
                        onClick={() => setMerge(true)}>
                        Continue with selection
                    </Button>
                </div>
            </div>
        </div>
    )
}