import React from "react"
import * as Yup from 'yup'
import Ajax from "../../../Ajax"
import { useFormik } from "formik"
import Field from "../fields/Field"
import Help from "../../../misc/Help"
import { Button } from "@mui/material"
import { DisplayValue } from "../../.."
import { Icons } from "../../../misc/Icons"
import { store } from "../../../stores/Store"
import { SelectOption } from "../fields/SelectField"


function formatOptionKey (option) {
    return `${option.statusId}::${option.reportType}::${option.ndsAccountUid}::${option.customerType}::${option.accountUid}`
}


export default function ReportRequestForm ({enquiry, reportOptions}) {

    var ndsAccountOptions: SelectOption[] = []
    var customerTypeOptions: SelectOption[] = []
    
    // Populate select field options
    reportOptions.forEach((reportOption) => {
        if (!ndsAccountOptions.find(o => o.value === reportOption.ndsAccountUid)) {
            ndsAccountOptions.push({value: reportOption.ndsAccountUid, label: reportOption.ndsAccountName})
        }
        if (!customerTypeOptions.find(o => o.value === reportOption.customerType)) {
            customerTypeOptions.push({value: reportOption.customerType, label: DisplayValue("customer_type", reportOption.customerType)})
        }
    })

    if (ndsAccountOptions.length > 1 || customerTypeOptions.length > 1) {
        return <ReportRequestAdvancedForm enquiry={enquiry} reportOptions={reportOptions} ndsAccountOptions={ndsAccountOptions} customerTypeOptions={customerTypeOptions} />
    }

    return <ReportRequestBasicForm enquiry={enquiry} reportOptions={reportOptions} />
}



function ReportRequestBasicForm ({enquiry, reportOptions}) {
    
    const [error, setError] = React.useState<string>("")
    const [submitting, setSubmitting] = React.useState(false)
    
    var noneLeftToRequest = !reportOptions.find(option => !option.message)


    const submit = async () => {
        var reportOptionsToSend = reportOptions.map(ro => {
            return {
                enquiryUid: enquiry.uid,
                userReference: null,
                statusId: ro.statusId,
                reportType: ro.reportType,
                ndsAccountUid: ro.ndsAccountUid,
                customerType: ro.customerType,
                accountUid: ro.accountUid,
            }
        })
        await Ajax.Report.Request(reportOptionsToSend).then(() =>
            store.AlertStore.Close()
        ).catch((response) => {
            setError(response.data?.detail ? response.data.detail : "Could not order reports because of a server error.")
        })
    }

    return (
        <div className={`form ${submitting ? "disabled" : ""}`}>

            <div className="form-header">
                <h2>Create Reports and Prepare Automations</h2>
            </div>

            <div className="form-fields">
                {noneLeftToRequest 
                    ? <span>Reports have been created and provisions for Automations have been made.</span>
                    : <span>Please click on Submit to provide the further information needed for reports and the automation of the Home Office forms (when applicable). Otherwise please click Cancel”</span>}

                {error ? <p className="warning">{error}</p> : null}
            </div>

            <div className="form-buttons">
                {noneLeftToRequest ? (
                    <Button color="success" variant="contained" className="btn btn-sm" onClick={() => store.AlertStore.Close()}>Close</Button>
                ) : (
                    <>
                        <Button color="primary" className="btn btn-sm" onClick={() => store.AlertStore.Close()} >Cancel</Button>
                        <Button
                            onClick={() => submit()}
                            color="success"
                            variant="contained"
                            disabled={noneLeftToRequest}
                            className="btn btn-sm">
                            Submit
                        </Button>
                    </>
                )}
            </div>
        </div>
    )
}


function ReportRequestAdvancedForm ({enquiry, reportOptions, customerTypeOptions, ndsAccountOptions}) {
    
    const [error, setError] = React.useState<string>("")
    const [submitting, setSubmitting] = React.useState(false)

    var formik = useFormik({

        initialValues: {
            userReference: "",
            ndsAccountUid: ndsAccountOptions?.length ? ndsAccountOptions[0].value : null,
            customerType: customerTypeOptions?.length ? customerTypeOptions[0].value : null,
        },
        
        validationSchema: Yup.object({}),

        onSubmit: async (values) => {

            setError("")
            setSubmitting(true)

            var valuesToSend = []

            Object.keys(values).forEach(optionKey => {
                if (values[optionKey] === true) {
                    
                    var optionKeySplit = optionKey.split("::")
                    
                    valuesToSend.push({
                        enquiryUid: enquiry.uid,
                        userReference: values.userReference ? values.userReference : null,
                        statusId: optionKeySplit[0],
                        reportType: optionKeySplit[1],
                        ndsAccountUid: optionKeySplit[2],
                        customerType: optionKeySplit[3],
                        accountUid: optionKeySplit[4],
                    })
                }
            })

            if (valuesToSend.length === 0) {
                var errorMessage = "Please select one or more reports"
                var validReportOptions = !!reportOptions.find(o => o.message === null)
                if (!validReportOptions && reportOptions.length) {
                    errorMessage = "Reports have already been provided, please close this window and see the reports section under the enquiry"
                }
                setError(errorMessage)
            } else {
                await Ajax.Report.Request(valuesToSend).then(() =>
                    store.AlertStore.Close()
                ).catch((response) => {
                    setError(response.data?.detail ? response.data.detail : "Could not order reports because of a server error.")
                })
            }

            setSubmitting(false)
        }
    })

    const handleSelectChange = (e) => {
        reportOptions.forEach((option, i) => {
            formik.values[`${option.statusId}-${option.reportType}-${option.ndsAccountUid}-${option.customerType}-${option.accountUid}`] = false
        })
        formik.handleChange(e)
    }

    var reportOptionsToShow = reportOptions.filter(option => {
        return option.ndsAccountUid === formik.values["ndsAccountUid"] && option.customerType === formik.values["customerType"]
    })

    var noneLeftToRequest = !reportOptions.find(option => !option.message)

    return (
        <form className={`form ${submitting ? "disabled" : ""}`} onSubmit={formik.handleSubmit} >

            <div className="form-header">
                <h2>Create Reports</h2>
            </div>

            <div className="form-fields">
                {noneLeftToRequest ? (
                    <span>You have already requested all available reports.</span>
                ) : (
                    <span>
                        Please select the reports that you would like to generate.
                        Please generate all reports if you would like to automate Home Office forms.
                        It may be that all of the reports are already up to date, if so click Cancel.
                    </span>
                )}

                <div style={{display: "grid", gridTemplateColumns: "200px 200px", gap: "10px"}}>
                    {ndsAccountOptions.length > 1
                        ? <Field.Select formik label="NDS Account" name="ndsAccountUid" options={ndsAccountOptions} value={formik.values["ndsAccountUid"]} onChange={handleSelectChange} />
                        : null}

                    {customerTypeOptions.length > 1
                        ? <Field.Select formik label="Customer Type" name="customerType" options={customerTypeOptions} value={formik.values["customerType"]} onChange={handleSelectChange} />
                        : null}
                </div>

                <ReportOptions formik={formik} options={reportOptionsToShow} />

                {error ? <p className="warning">{error}</p> : null}
            </div>


            <div className="form-buttons">
                {noneLeftToRequest ? (
                    <Button color="success" variant="contained" className="btn btn-sm" onClick={() => store.AlertStore.Close()}>Close</Button>
                ) : (
                    <>
                        <Button color="primary" className="btn btn-sm" onClick={() => store.AlertStore.Close()}>Cancel</Button>

                        <Button
                            type="submit"
                            color="success"
                            variant="contained"
                            disabled={noneLeftToRequest}
                            className="btn btn-sm">
                            Submit
                        </Button>
                    </>
                )}
            </div>
        </form>
    )
}



function ReportOptions ({formik, options}) {

    var groupedReportOptionsToShow = {}

    options.forEach((option, i) => {
        if (option.statusId in groupedReportOptionsToShow) {
            groupedReportOptionsToShow[option.statusId].push(option)
        } else {
            groupedReportOptionsToShow[option.statusId] = [option]
        }
    })

    return (
        <div style={{maxHeight: "50vh", overflow: "auto", display: "grid"}}>
            {Object.keys(groupedReportOptionsToShow).map((statusId, i) => {

                var options = groupedReportOptionsToShow[statusId]

                if (!options?.length) return <React.Fragment key={i} />

                var outcome = DisplayValue("outcome", options[0].outcome)
                var color = store.EnquiryStore.outcomeColor(options[0].outcome)

                return (
                    <div key={i}>
                        <div style={{display: "flex", gap: "12px", alignItems: "baseline"}}>
                            <h4>{DisplayValue("nationality", statusId)}</h4>
                            <div style={{display: "flex", gap: "4px", alignItems: "center", color: color, fontWeight: 600, margin: "0"}}>
                                {outcome}
                            </div>
                        </div>
    
                        <div style={{margin: "8px 0 8px 8px", display: "grid", gap: "6px"}}>
                            {options.map((option, i) => {

                                var formikKey = formatOptionKey(option)
                                var label = DisplayValue("report_type", option.reportType)
    
                                return (
                                    <label key={i} style={{display: "grid", gridTemplateColumns: "20px auto", gap: "8px", alignItems: "center"}}>
                                        <div>
                                            {option.message ? (
                                                <Help content={<Icons.Info style={{fontSize: "12px"}} />}>{option.message}</Help>
                                            ) : (
                                                <Field.Checkbox
                                                    formik
                                                    name={formikKey}
                                                    value={formik.values[formikKey]}
                                                    warning={false}
                                                    onChange={formik.handleChange} />
                                            )}
                                        </div>
                                        <div>{label}</div>
                                    </label>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}