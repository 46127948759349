import React from "react"
import Ajax from "../../../Ajax"
import { Alert } from "../../.."
import { Button } from "@mui/material"
import { observer } from "mobx-react-lite"
import { Icons } from "../../../misc/Icons"
import { store } from "../../../stores/Store"
import { Questionnaire } from "../../../models/Interfaces"
import QuestionnaireDetails from "../../views/questionnaire_mgmt/QuestionnaireDetails"
import QuestionnaireSelectMultipleField from "../questionnaire/QuestionnaireSelectMulitpleField"


export default observer(function EnquiryResume () {

    const [loading, setLoading] = React.useState(false)
    const [questionnaireUidArray, setQuestionnaireUidArray] = React.useState<string[]>([])
    const [previewQuestionnaire, setPreviewQuestionnaire] = React.useState<Questionnaire>(null)

    const submit = async () => {
        setLoading(true)
        
        await Ajax.Enquiry.Rerun(store.EnquiryStore.enquiry.uid, questionnaireUidArray).then(() => {
            store.AlertStore.Close()
        }).catch((response) => {
            Alert({message: response.data?.detail ? response.data.detail : "Couldn't resume enquiry"})
        })
        
        setLoading(false)
    }

    const preview = async (uid) => {
        setLoading(true)
        
        await Ajax.Questionnaire.Get(uid).then((response) => {
            setPreviewQuestionnaire(response.data)
        }).catch(() => {
            Alert({message: "Failed to get questionnaire"})
        })

        setLoading(false)
    }

    var highlightedOptions = store.EnquiryStore.enquiry?.dataSources
        ? store.EnquiryStore.enquiry.dataSources.map((source) => source.sourceUid)
        : []

    if (previewQuestionnaire) {
        return (
            <div className={loading ? "form disabled" : "form"}>
                <QuestionnaireDetails 
                    height="260px"
                    questionnaire={previewQuestionnaire} />

                <Button 
                    className="btn btn-sm" 
                    variant="contained" 
                    color="success"
                    onClick={() => setPreviewQuestionnaire(null)}>
                    <Icons.ChevronLeft /> Exit preview
                </Button>
            </div>
        )
    }

    return (
        <div className="form">
            <div>
                <p>Select a questionnaire to load answers in the enquiry (optional).</p>
                <p>Questionnaires which have been loaded previously will listed be in <b>bold</b>.</p>
                <QuestionnaireSelectMultipleField
                    value={questionnaireUidArray} 
                    highlightedOptions={highlightedOptions}
                    onRowClick={(questionnaireUid) => preview(questionnaireUid)}
                    onChange={(questionnaireUidArray) => setQuestionnaireUidArray(questionnaireUidArray)} />
            </div>

            <div className="form-buttons">
                <Button className="btn btn-sm" onClick={() => store.AlertStore.Close()}>
                    Cancel 
                </Button>
                
                <Button 
                    className="btn btn-sm"
                    color="success"
                    variant="contained"
                    disabled={loading}
                    onClick={() => submit()}>
                    {questionnaireUidArray ? "Run using this questionnaire" : "Run without questionnaire"}
                </Button>
            </div>
        </div>
    )
})