import React from "react"
import { store } from "../../../../stores/Store"
import { EnquiryInitiateDto } from "../../../../models/Enquiry"
import EnquirySubjectFormSection from "./EnquirySubjectFormSection"
import EnquiryAssessmentFormSection from "./EnquiryAssessmentFormSection"
import EnquiryDataSourcesFormSection from "./EnquiryDataSourcesFormSection"
import Ajax from "../../../../Ajax"
import { Alert } from "../../../.."


export default function EnquiryCreateForm ({caseUid}) {

    const [enquiry, setEnquiry] = React.useState<EnquiryInitiateDto>(null)
    const [questionnaireUidArray, setQuestionnaireUidArray] = React.useState<string[]>(null)

    const onSubmit = async (subjectData) => {
        var enquiryInitiateDto = {
            ...enquiry,
            caseUid: caseUid,
            subjectUid: subjectData.subjectUid,
            subjectInfo: subjectData.subjectInfo,
            questionnaireUidList: questionnaireUidArray,
        }

        await Ajax.Enquiry.Initiate(enquiryInitiateDto).then((response) => {
            store.NodeStore.refreshFolderNode()
            store.AlertStore.Close()
        }).catch(response => {
            Alert({color: "error", message: response.data?.detail ? response.data.detail : "Failed to create enquiry"})
        })
    }
    
    return (
        <div className="form" style={{width: "500px"}}>
            <EnquiryAssessmentFormSection 
                hidden={!!enquiry}
                caseUid={caseUid}
                onCancel={() => store.AlertStore.Close()} 
                onSubmit={(data) => setEnquiry(data)} />

            <EnquiryDataSourcesFormSection
                hidden={!enquiry || !!questionnaireUidArray}
                onCancel={() => setEnquiry(null)} 
                onSubmit={(data) => setQuestionnaireUidArray(data)} />
        
            <EnquirySubjectFormSection 
                hidden={!enquiry || !questionnaireUidArray}
                questionnaireUidArray={questionnaireUidArray}
                onCancel={() => setQuestionnaireUidArray(null)} 
                onSubmit={(data) => onSubmit(data)} />
        </div>
    )
}


