import React from "react";
import { format, isDate, isValid } from "date-fns"
import { InputFieldProps } from "../../../models/Interfaces";
import Field from "./Field";


interface OpenDateCheckbox {
    value: string;
    label: string;
}


interface DateFieldProps extends InputFieldProps {
    value?: string | Date;
    minDate?: string | Date;
    maxDate?: string | Date;
    openDateCheckbox?: OpenDateCheckbox;
    hideOpener?: boolean;
    native?: boolean
}


export default function DateField ({
    name = "",
    value = undefined,
    label = "",
    onChange,
    warning = "",
    helpText = "",
    formik = false,
    disabled = false,
    autoFocus = false,
    hideOpener = true,
    minDate = "1800-01-01",
    maxDate = "3000-01-01",
    openDateCheckbox = undefined,
}: DateFieldProps) {

    const [secondaryCheckbox, setSecondaryCheckbox] = React.useState(false)
    const [revertValue, setRevertValue] = React.useState(null)

    // Turn any value into ISO date string
    if (typeof value === "string" && value.includes("T")) {
        value = value.split("T")[0]
    } else if (isDate(value) && isValid(value)) {
        value = format(new Date(minDate), "yyyy-MM-dd")
    } else {
        value = value?.toString()
    }

    return (
        <div className="form-field field-date">

            <div style={{display: "flex", alignItems: "end"}}>
                <div className="field-date-native">
                    {label ? <label>{label}</label> : null}
                    
                    <input
                        type="date"
                        name="date"
                        // value={value}
                        value={value}
                        required={false}
                        disabled={secondaryCheckbox || disabled}
                        autoFocus={autoFocus}
                        min={isDate(minDate) && isValid(minDate) ? format(new Date(minDate), "yyyy-MM-dd"): minDate?.toString()}
                        max={isDate(maxDate) && isValid(maxDate) ? format(new Date(maxDate), "yyyy-MM-dd"): maxDate?.toString()}
                        onChange={(e) => onChange(e)} />
                </div>
                
                {openDateCheckbox ? (

                    // Secondary checkboxes are for when the value does not yet exist e.g. the person hasn't died yet.
                    // When checked it should set the value to secondaryCheckboxValues[0] and then disabled the 
                    // primary input. When unchecked it should revert to the value of the primary input.

                    <Field.Checkbox
                        formik
                        name="asdf"
                        label={openDateCheckbox.label}
                        value={secondaryCheckbox}
                        warning={false}
                        onChange={(e) => {
                            
                            var event = { target: {
                                name: name, 
                                value: openDateCheckbox.value
                            }}
                            
                            if (e.target.checked) {
                                setRevertValue(value)
                                setSecondaryCheckbox(true)
                            } else {
                                setSecondaryCheckbox(false)
                                event.target.value = revertValue ? format(new Date(revertValue), "yyyy-MM-dd") : null
                            }
                            
                            formik ? onChange(event) : onChange(event.target)
                        }} /> 

                ) : null}
                
            </div>

            {warning !== false && typeof warning === "string" ? <span className="warning">{warning}</span> : null}

            {helpText ? <span className="help-text">{helpText}</span> : null}

        </div>
    )
}