import {Column, Table, AutoSizer} from 'react-virtualized';
import { AlertModal, DisplayValue } from '../../..';
import { store } from '../../../stores/Store';
import { Button, LinearProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { format, isValid } from 'date-fns';
import React from 'react';
import ViewQuestionnaire from './ViewQuestionnaire';
import { Icons } from '../../../misc/Icons';
import Field from '../../form/fields/Field';


interface QuestionnaireTableProps {
    width?: string;
    height?: string;
    onRowClick?: Function;
    onCheckboxClick?: Function;
    size?: "sm" | "md";
    highlightedOptions?: string[];
    selectedOptions?: string[];
}


export default observer(function QuestionnaireTable ({
    onRowClick = (uid: string) => {},
    onCheckboxClick = (uid: string) => {},
    height = "400px",
    size = "md",
    highlightedOptions = [],
    selectedOptions = null
}: QuestionnaireTableProps) {

    const { loading, questionnaires, filterQuestionnaires, getQuestionnaires } = store.QuestionnaireStore
    const minWidth = 990
    var rowHeight = size === "md" ? 40 : 30
    var list = filterQuestionnaires(questionnaires)

    React.useEffect(() => {
        console.log("selectedOptions", selectedOptions)
        if (!questionnaires?.length) {
            getQuestionnaires()
        }
    }, [])

    const formatDate = (dateString, placeholder = "") => {
        if (!dateString) return <span className="font-grey">{placeholder}</span>
        var date = new Date(dateString)
        return isValid(date) ? format(date, "dd/MM/yyyy H:mm") : <span className="font-grey">{placeholder}</span>
    }

    const CellWrapper = ({children, uid}) => {

        var highlight = highlightedOptions.includes(uid)

        return (
            <div style={{fontWeight: highlight ? 600 : 400}}>
                {children}
            </div>
        )
    }

    return (
        <div className={`rv-table-wrapper rv-table-${size}`} style={{minHeight: height}}>
            <AutoSizer>
                {({height, width}) => (
                    <Table
                        width={width < minWidth ? minWidth : width}
                        height={height}
                        headerHeight={rowHeight}
                        rowHeight={rowHeight}
                        rowCount={list?.length}
                        rowGetter={({index}) => list[index]}
                        onRowClick={({event, index, rowData}) => {
                            // Don't do anything if click was on a input field
                            if (event.target.nodeName === "DIV") {
                                onRowClick(rowData.uid)
                            }
                        }} >

                        {Array.isArray(selectedOptions) ? (
                            <Column
                                dataKey=""
                                width={30}
                                headerRenderer={() => <></>}
                                cellRenderer={({rowData}) => {
                                    
                                    var number = selectedOptions.indexOf(rowData.uid)
                                    var disabled = number < 0 && selectedOptions?.length > 5
                                    var style = {fontSize: "18px"}

                                    return (
                                        <Button 
                                            disabled={disabled}
                                            style={{padding: 0, margin: 0, width: 0, opacity: disabled ? 0.4 : 1}} 
                                            color="info" 
                                            onClick={(e) => {
                                                onCheckboxClick(rowData.uid)
                                        }}>
                                            <CheckIcon index={number} />
                                        </Button>
                                    )
                                }} />
                        ) : null}

                        <Column
                            dataKey=""
                            width={150}
                            headerRenderer={() => <ColumnHeader value="displayName" />}
                            cellRenderer={({rowData}) => (
                                <CellWrapper uid={rowData.uid}>
                                    {rowData.displayName}
                                </CellWrapper>
                            )} />

                        <Column
                            dataKey=""
                            width={150}
                            headerRenderer={() => <ColumnHeader value="template" />}
                            cellRenderer={({rowData}) => (
                                <CellWrapper uid={rowData.uid}>
                                    {rowData.template.displayName}
                                </CellWrapper>
                            )} />

                        <Column
                            dataKey=""
                            width={150}
                            headerRenderer={() => <ColumnHeader value="respondent" />}
                            cellRenderer={({rowData}) => (
                                <CellWrapper uid={rowData.uid}>
                                    {rowData.respondent?.respondentEmail}
                                </CellWrapper>
                            )} />

                        <Column
                            dataKey=""
                            width={150}
                            headerRenderer={() => <ColumnHeader value="notificationEmail" />}
                            cellRenderer={({rowData}) => (
                                <CellWrapper uid={rowData.uid}>
                                    {rowData.notificationEmail}
                                </CellWrapper>
                            )} />

                        <Column
                            dataKey=""
                            width={140}
                            headerRenderer={() => <ColumnHeader value="createdAt" />}
                            cellRenderer={({rowData}) => (
                                <CellWrapper uid={rowData.uid}>
                                    {formatDate(rowData.createdAt)}
                                </CellWrapper>
                            )} />

                        <Column
                            dataKey=""
                            width={140}
                            headerRenderer={() => <ColumnHeader value="updatedAt" />}
                            cellRenderer={({rowData}) => (
                                <CellWrapper uid={rowData.uid}>
                                    {formatDate(rowData.updatedAt, "Not started")}
                                </CellWrapper>
                            )} />

                        <Column dataKey="" width={35}   headerRenderer={(rowData) => <CellWrapper uid={rowData.uid}><></></CellWrapper>} cellRenderer={(data) => <div style={{marginLeft: "auto"}}></div>} />
                    </Table>
                )}
            </AutoSizer>

            {loading ? <LinearProgress color="primary" style={{marginTop: `${rowHeight}px`}} /> : null}
        </div>
    )
})



function ColumnHeader ({value}) {

    var {order, orderedBy, setOrder, setOrderedBy} = store.QuestionnaireStore

    return (
        <div>
            <Button className="btn btn-lowercase" color="info" onClick={() => {
                if (orderedBy !== value) {
                    setOrderedBy(value)
                } else {
                    setOrder(order === "asc" ? "desc" : "asc")
                }
            }}>
                {DisplayValue("dict_key", value)}

                {orderedBy === value ? (
                    <>
                        {order === "asc" ? <Icons.ArrowDown style={{fontSize: "12px"}} /> : <Icons.ArrowUp style={{fontSize: "12px"}} />}
                    </>
                ) : null}
            </Button>
        </div>
    )
}

export function CheckIcon ({index = null}) {
    
    var style = {fontSize: "18px"}
    
    switch (index) {
        case 0:
            return <Icons.One style={{...style, color: "#333333"}} />
        case 1:
            return <Icons.Two style={{...style, color: "#555555"}} />
        case 2:
            return <Icons.Three style={{...style, color: "#777777"}} />
        case 3:
            return <Icons.Four style={{...style, color: "#888888"}} />
        case 4:
            return <Icons.Five style={{...style, color: "#999999"}} />
        case 5:
            return <Icons.Six style={{...style, color: "#aaaaaa"}} />
        case "enquiry":
            return <Icons.Enquiry style={{...style, color: "#333333"}} />
        default:
            return <Icons.Checkbox style={{...style, color: "#333333"}} />
    }
}