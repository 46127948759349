import React from "react";
import { store } from "../../../stores/Store";
import { observer } from "mobx-react-lite";
import { Button } from "@mui/material";
import ViewQuestionnaire from "../questionnaire_mgmt/ViewQuestionnaire";
import { AlertModal } from "../../..";
import { DataSource } from "../../../models/Interfaces";

export default observer(function QuestionLog () {

    const { dataSources, dataSourceUsage } = store.QuestionnaireStore

    const ref = React.useRef(null)

    React.useEffect(() => {
        if (ref.current) {
            ref.current.scrollTo(0, ref.current.scrollHeight)
        }
    }, [])

    // if (!dataSourceLabels) return <h3>No questionnaire loaded</h3>

    return (
        <div className="question-log">
            <h2>Sourced Data</h2>

            <p>
                Data sources: {dataSources?.map((source: DataSource, i) => (
                    <>
                        <span
                            style={{marginLeft: "6px"}}
                            className="link" 
                            onClick={() => AlertModal({width: "700px", body: <ViewQuestionnaire uid={source.sourceUid} />})}>
                            {source.label}
                        </span>
                        {i < dataSources.length - 1 ? "," : ""}
                    </>
                ))}
            </p>

            <div className="question-log-content" ref={ref}>
                {dataSourceUsage?.map((ir, i) => (
                    <div key={i}>
                        <p className="message">{ir.question}</p>
                        <p className="message answer">{ir.answer}</p>
                    </div>
                ))}
            </div>
        </div>
    )
})