import { store } from './stores/Store'
import axios, { AxiosResponse } from 'axios'
import { AlertModal } from './index'
import LoadQuestionnaire from './components/form/enquiry/LoadQuestionnaire'
import { EnquiryInitiateDto } from './models/Enquiry'

var isWaitingForTokenRefresh = false

const refreshToken = async () => {

    // If there is already refreshToken request underway, wait for a second and then resolve.
    // This will trigger the original request which should have a valid token by this time.
    if (isWaitingForTokenRefresh === true) {
        return await new Promise(resolve => setTimeout(resolve, 1000));
    }

    isWaitingForTokenRefresh = true

    try {
        var {data} = await axios_unintercepted.post("session/refresh-token")
        store.AppStore.setToken(data.token)
        return Promise.resolve()
    } catch {
        await store.AppStore.resetApp()
        return Promise.reject()
    } finally{
        isWaitingForTokenRefresh = false
    }
}

const assignAuthorizationHeader = (config) => {
    const token = window.localStorage.getItem("aora_jwt")
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}

// Create the main axios instance
const axios_main = axios.create();
axios_main.defaults.withCredentials = true;
axios_main.defaults.baseURL = process.env.REACT_APP_API_URL
axios_main.interceptors.request.use(config => {
    return assignAuthorizationHeader(config)
})

// Create a seperate axios instance without the response interceptor.
// We can use this to make requests from the response interceptor
// and avoid triggering infinite loops of requests.
const axios_unintercepted = axios.create();
axios_unintercepted.defaults.withCredentials = true;
axios_unintercepted.defaults.baseURL = process.env.REACT_APP_API_URL
axios_unintercepted.interceptors.request.use(config => {
    return assignAuthorizationHeader(config)
})

axios_unintercepted.interceptors.response.use(
    async (response) => { // Success
        return Promise.resolve(response)
    },
    async (error) => { // Fail
        return Promise.reject(error.response)
    }
)

// Add response interceptor to main axios instance
// ---------------------------------------------------------------------------------------------
axios_main.interceptors.response.use(

    async (response) => { // Success
        return Promise.resolve(response)
    },

    async (error) => { // Fail

        if (!error.response) {
            if (store.AppStore.appLoaded) {
                AlertModal({id: "connection_problem", title: "Something went wrong", body: "The server didn't respond", size: "sm", actions: [
                    {label: "Refresh", action: () => window.location.reload()}
                ]})
            } else {
                store.AppStore.navigate("/error/The server was unresponsive")
                store.AppStore.setAppLoaded()
            }
            return Promise.reject()
        }

        const {status, data, config} = error.response

        // Handle errors by http status
        switch (status) {
            case 401:
                return await refreshToken().then(() => {
                    return Promise.resolve(axios_main.request(config)) // Resolve the original request as if nothing happened
                })

            case 440: // This code is used by the server when it wants to force the session to end.
                await store.AppStore.logout()
                AlertModal({title: data.title, body: data.detail})
                return Promise.reject()

            default:
                break
        }

        return Promise.reject(error.response)
    }
)

// Make all successful requests resolve a promise
// ---------------------------------------------------------------------------------------------
const returnResponse = (response: AxiosResponse) => { return Promise.resolve(response) }


// Define the behaviour for each type of request
// ---------------------------------------------------------------------------------------------
const requests = {
    get: (url: string) => axios_main.get(url).then(returnResponse),
    put: (url: string, data: any) => axios_main.put(url, data).then(returnResponse),
    post: (url: string, data: any) => axios_main.post(url, data).then(returnResponse),
    delete: (url: string, data: any) => axios_main.delete(url, data).then(returnResponse),
    fileDownload: (url: string, fileName: string) => axios_main.get(url, {responseType: 'blob'}).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName) //or any other extension
        document.body.appendChild(link)
        link.click()
    }),
    fileUpload: (url: string, data: any, config: any) => axios_main.post(url, data, {
        cache: false,
        contentType: false,
        processData: false,
        ...config
    }).then(returnResponse),
}


// Map the API to the front-end data model
// ---------------------------------------------------------------------------------------------
const Ajax = {
    Session: {
        Info: () =>                                                 requests.get("session"),
        Language: () =>                                             requests.get("session/language"),
        SessionDebug: () =>                                         requests.get("session/debug"),
        ConnectionOptions: () =>                                    requests.get("session/connection-options"),
        Log: (message: string) =>                                   requests.post(`session/log`, {content: message}),
        UpdatePassword: (values: any) =>                            requests.post(`session/update-password`, values),
        TicketRequest: (formData: FormData) =>                      requests.post("session/ticket-request", formData),
        Login: (data: any) =>                                       axios_unintercepted.post("session/obscure-login-endpoint", data),
        LoginOAuth: (data: any) =>                                  axios_unintercepted.post("session/login-oauth", data),
        Mfa: (data: any) =>                                         axios_unintercepted.post("session/mfa", data),
        Logout: () =>                                               axios_unintercepted.post("session/logout"),
        RefreshToken: () =>                                         refreshToken(),
        Freshworks: (action: string, json: string = "") =>          requests.post(`session/freshworks`, {action: action, json: json})
    },

    Message: {
        Test: (testCount: number) =>                                requests.post(`message/test/${testCount}`, null),
        Respond: (values, certainties) =>                           requests.post(`message/respond/ir`, {values: values, certainties: certainties}),
        RespondPopup: (response: string) =>                         requests.post("message/respond/pr", {response: response}),
        Cancel: () =>                                               requests.post(`message/cancel`, null),
        CancelPopup: () =>                                          requests.post("message/cancel/pr", null),
        RespondAbsence: (absenceBlock: any) =>                      requests.post(`message/respond/absence`, absenceBlock),
    },

    Node: {
        Get: (uid: string) =>                                       requests.get(`node/${uid}`),
        GetAccounts: () =>                                          requests.get(`node/accounts`),
        Delete: (uid: string) =>                                    requests.delete(`node/${uid}`, {}),
        // BeginEdit: (nodeUid: string) =>                          requests.get(`node/edit/${nodeUid}`),
        Edit: (type: "sponsor"|"account"|"user"|"folder"|"case", nodeDto: any) =>
                                                                    requests.put(`node/${type}`, nodeDto),
        Move: (uid: string, newParentUid: string) =>                requests.post(`node/move/${uid}/${newParentUid}`, null),
        Create: (type: "sponsor"|"account"|"user"|"folder"|"case"|"person"|"child", parentUid: string, nodeDto: any) =>
                                                                    requests.post(`node/${type}/${parentUid}`, nodeDto),
        Path: (ancestorUid: string, nodeId: string) =>              requests.get(`node/path/${nodeId}/${ancestorUid}`),
        Search: (type: "territory"|"occupation", searchString: any) =>
                                                                    requests.get(`node/search/${type}/${searchString}`),
        GetPath: (uid: string) =>                                   requests.get(`node/path/${uid}`),
        GetSuccessors: (uid: string) =>                             requests.get(`node/successors/${uid}`)
    },

    Entity: {
        SaveEdit: () =>                                             requests.put(`entity/edit/save`, null),
        // CancelEdit: () =>                                           requests.put(`entity/edit/cancel`, null),
        BeginEdit: (nodeUid: string) =>                             requests.get(`entity/edit/${nodeUid}`),
        Edit: (nodeUid: string) =>                                  requests.put(`entity/edit/${nodeUid}`, null),
        AddItem: (parentUid: string) =>                             requests.post(`entity/${parentUid}`, null),
        DeleteAttribute: (nodeUid: string) =>                       requests.delete(`entity/attribute/${nodeUid}`, null),
        Update: (type: "certainty" | "names" | "sex", node: any) =>
                                                                    requests.put(`entity/update/${type}`, node),
    },

    Enquiry: {
        Edit: (enquiryUid, section) =>                              requests.put(`enquiry/${enquiryUid}`, section),
        Create: (enquiryData: EnquiryInitiateDto) =>                requests.post(`enquiry`, enquiryData),
        Initiate: (enquiryData: EnquiryInitiateDto) =>              requests.post(`enquiry/initiate`, enquiryData),
        Run: (enquiryUid, questionnaireUidArray: string[] = []) =>  requests.post(`enquiry/run/${enquiryUid}/${questionnaireUidArray.join(",")}`, null),
        Rerun: (enquiryUid, questionnaireUidArray: string[] = []) =>requests.post(`enquiry/rerun/${enquiryUid}/${questionnaireUidArray.join(",")}`, null),
        LoadQuestionnaires: (caseUid: string, questionnaireUidArray: string[] = []) =>
                                                                    requests.post(`enquiry/load-questionnaires/${caseUid}/${questionnaireUidArray.join(",")}`, null),
        GetOptions: (caseUid: string, legalSystem: string, interestDate: string) =>
                                                                    requests.get(`enquiry/options/${legalSystem}/${interestDate}/${caseUid}`),
        GetReportOptions: (enquiryUid: string) =>                   requests.get(`enquiry/report-options/${enquiryUid}`),
        GetNewSubjectDetails: (questionnaireUids: string[]) =>   requests.post(`enquiry/new-subject-details`, questionnaireUids),
    },

    Report: {
        All: () =>                                                  requests.get("report"),
        Get: (reportUid: string) =>                                 requests.get(`report/${reportUid}`),
        File: (report: any, fileName: string) =>                    requests.fileDownload(`report/file/${report.uid}`, fileName),
        Request: (reportRequest: any) =>                            requests.post(`report`, reportRequest),
        Regenerate: (report: any) =>                                requests.put(`report/regenerate`, report),
        MarkInvalid: (report: any) =>                               requests.put(`report/mark-invalid`, report),
    },

    Questionnaire: {
        All: () =>                                                  requests.get("questionnaire"),
        Get: (questionnaireUid: string) =>                          requests.get(`questionnaire/${questionnaireUid}`),
        Update: (questionnaire: any) =>                             requests.put(`questionnaire`, questionnaire),
        Delete: (questionnaireUid: any) =>                          requests.delete(`questionnaire/${questionnaireUid}`, null),
        Export: (questionnaireUid: any) =>                          requests.fileDownload(`questionnaire/export/${questionnaireUid}`, `q-export-${questionnaireUid}.json`),
        Import: (respondentEmail: string, name: string, data: FormData) =>                                 
                                                                    requests.fileUpload(`questionnaire/import/${respondentEmail}/${name}`, data, null),
        Create: (templateKey: string, respondentEmail: string, name: string) =>
                                                                    requests.post(`questionnaire/create/${templateKey}/${respondentEmail}/${name}`, null),
        EnableAdvisor: (accountUid: string) =>                      requests.post(`questionnaire/advisor/enable/${accountUid}`, null),
        UpdateAdvisorLogo: (formData: FormData, advisorUid) =>      requests.put(`questionnaire/advisor/logo/${advisorUid}`, formData),
        GetUpload: (uid) =>                                         requests.get(`questionnaire/upload/${uid}`),
    },

    QuestionnaireTemplate: {
        All: () =>                                                  requests.get(`questionnaire/template/all`),
        Get: (key: string) =>                                       requests.get(`questionnaire/template/${key}`),
        Create: (template: any) =>                                  requests.post(`questionnaire/template`, template),
        Update: (template: any) =>                                  requests.put(`questionnaire/template`, template),
    },

    Automation: {
        Run: (enquiryUid: string, automationId: string, additionalData: string = "{}") =>
                                                                    requests.post("automation", {enquiryUid: enquiryUid, automationId: automationId, additionalData: additionalData}),
        BuildAutomationData: (enquiryUid: string, additionalData: string = "{}", subjectPrefix: string) =>
                                                                    requests.post("automation/data", {enquiryUid: enquiryUid, additionalData: additionalData, subjectPrefix: subjectPrefix}),
        PromptResponse: (automatorId: string, response: string) =>  requests.post("automation/prompt-response", {automatorId: automatorId, promptResponse: response}),
        Cancel: (automatorId: string) =>                            requests.post(`automation/cancel/${automatorId}`, null),
        Options: () =>                                              requests.get(`automation/options`)
    },
}

export default Ajax