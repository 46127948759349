import React from "react"
import * as Yup from 'yup'
import Ajax from "../../../../Ajax"
import { format, isValid } from "date-fns"
import { Alert, DisplayDictionary, DisplayValue } from "../../../.."
import { EnquiryInitiateDto } from "../../../../models/Enquiry"
import { Button, CircularProgress, LinearProgress } from "@mui/material"
import Field from "../../fields/Field"
import { useFormik } from "formik"
import EnquirySettingsForm from "./EnquirySettingsFormSection"
import { Icons } from "../../../../misc/Icons"

export default function EnquiryAssessmentFormSection ({
    hidden,
    caseUid,
    onCancel = () => {},
    onSubmit = (enquiry) => {}
}) {

    const [loading, setLoading] = React.useState(true)
    const [enquiry, setEnquiry] = React.useState<EnquiryInitiateDto>(null)
    const [showSettings, setShowSettings] = React.useState(false)

    const formik = useFormik({

        initialValues: {
            ruleSystem: "UK",
            interestDate: format(new Date(), "yyyy-MM-dd"),
            status: null
        },

        validationSchema: Yup.object({
            ruleSystem: Yup.string().required("Please choose a legal system"),
            interestDate: Yup.date()
                .required("This field is required")
                .min(new Date("1950-01-01"), `After 1950-01-01`)
                .max(new Date("2100-01-01"), `Must be before year 2100`)
                .typeError("Please choose an interest date"),
            status: Yup.string().required("Please select the legal status to be evaluated")
        }),

        onSubmit: (values, actions) => {
            
            var newStatusNodes = [...enquiry.statuses].map(status => {
                return { ...status, selected: values.status === status.code }
            })
            
            onSubmit({...enquiry, statuses: newStatusNodes})
        }
    })

    React.useEffect(() => {
        getEnquiryOptions(formik.values.interestDate, formik.values.ruleSystem)
    }, [])

    const getEnquiryOptions = async (interestDate, ruleSystem) => {
        setLoading(true)

        if (isValid(new Date(interestDate)) && new Date(interestDate) > new Date("1950-01-01")) {
            await Ajax.Enquiry.GetOptions(caseUid, ruleSystem, interestDate.toString()).then((response) => {

                setEnquiry({
                    interestDate: new Date(interestDate),
                    ruleSystem: ruleSystem,
                    statuses: response.data.list1,
                    settings: response.data.list2
                })
                setLoading(false)
                formik.setFieldValue("status", null)
    
            }).catch((response) => {
                Alert({message: response.data?.detail ? response.data.detail : "Error getting enquiry options"})
            })
        }
    }

    if (hidden) return <></>

    return (
        <form onSubmit={formik.handleSubmit} className="form">
            <h2>Create Enquiry<span style={{color: "grey", fontWeight: 400}}> - Assessment</span></h2>

            {enquiry && showSettings ? (
                    <EnquirySettingsForm 
                        settingNodes={enquiry.settings}
                        onSubmit={(settingNodes) => {
                            setEnquiry({...enquiry, settings: settingNodes})
                            setShowSettings(false)
                        }} /> 
                ) : (
                    <>
                        <div>
                            <div style={{display: "grid", gridTemplateColumns: "150px 150px", gap: "10px"}}>
                                <Field.Date
                                    formik
                                    name="interestDate"
                                    label="Interest date"
                                    value={formik.values.interestDate}
                                    warning={formik.errors.interestDate}
                                    onChange={(e) => {
                                        formik.setFieldValue("interestDate", e.target.value)
                                        getEnquiryOptions(e.target.value, formik.values.ruleSystem)
                                    }} />

                                <Field.Select
                                    formik
                                    name="ruleSystem"
                                    label="Rule System"
                                    value={formik.values.ruleSystem}
                                    warning={formik.errors.ruleSystem}
                                    options={DisplayDictionary("legal_system", true)} 
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                        getEnquiryOptions(formik.values.interestDate, e.target.value)
                                    }} />
                            </div>

                            {enquiry ? (
                                <Field.CheckboxSelect
                                    formik
                                    name="status"
                                    multiple={false}
                                    disabled={loading}
                                    label="Select the status you would like to evaluate"
                                    value={formik.values.status}
                                    warning={formik.submitCount && formik.errors.status ? formik.errors.status : null}
                                    options={getStatusOptionsFromStatusSection(enquiry.statuses)}
                                    onChange={formik.handleChange} />
                            ) : (
                                <CircularProgress style={{width: "20px", height: "20px", margin: "50px auto", display: "flex"}} />
                            )}
                        </div>

                        <div className="form-buttons">
                            <div className="form-buttons">
                                <Button 
                                    className="btn btn-sm" 
                                    onClick={() => onCancel()}>
                                    Cancel
                                </Button>
                                
                                <Button 
                                    className="btn btn-sm link" 
                                    disabled={!enquiry}
                                    onClick={() => setShowSettings(true)}>
                                    <Icons.Settings /> Edit enquiry settings
                                </Button>
                            </div>
                            
                            <Button 
                                className="btn btn-sm" 
                                variant="contained" 
                                color="success"
                                disabled={!enquiry}
                                type="submit">
                                Next
                            </Button>
                        </div>
                    </>
                )}

            
        </form>
    )
}


function getStatusOptionsFromStatusSection (statusNodes) {

    if (!statusNodes) return []

    // Transform flat list of statuses into a tree structured by type
    var nationalityOptionsNested = []
    var typeOrder = ["SW", "CITIZEN", "PROTÉGÉ", "PR_STATUS", "TR_STATUS"]

    // Add each status to tree
    typeOrder.forEach((category => {

        var statuses = statusNodes.filter(nat => category.includes(nat.category))

        if (category === "PR_STATUS") {
            statuses = statuses.reverse()
        }

        if (statuses?.length) {
            nationalityOptionsNested.push({
                label: DisplayValue("status_tree_category", category),
                value: category,
                children: statuses.map(status => {
                    return {
                        value: status.code,
                        label: status.label,
                        // disabled: disabled
                    }
                })
            })
        }
    }))

    return nationalityOptionsNested
}