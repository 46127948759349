import { useEffect } from 'react'
import { store } from './stores/Store'
import { observer } from 'mobx-react-lite'
import {LinearProgress} from '@mui/material'
import { useParams } from 'react-router-dom'
import Header from './components/layout/Header'
import { Outlet, Route, Routes } from 'react-router-dom'
import ErrorBoundary from './misc/ErrorBoundary'
import NotFound from './components/errors/NotFound'
import ErrorView from './components/errors/ErrorView'
import MainView from './components/views/main/MainView'
import GuestView from './components/views/guest/GuestView'
import UiDemoView from './components/views/ui_demo/UiDemoView'
import React from 'react'
import ViewBanner from './components/layout/ViewBanner'
import BreadCrumb from './components/views/main/BreadCrumb'


export default observer(function App() {

	const {AppStore} = store

	useEffect(() => {

		// This is where we re-authenticate after a page refresh
		const loadAuthentication = async () => {

			// This page-refresh-authentication is dependent on either a
			// pre-existing token in session storage or auth_code in URI

			// Attempt to get user with existing token
			if (AppStore.token) {

				await AppStore.init().then(() => {
					// Redirect to main view if not already on an authorized path
					if (!window.location.pathname.includes("/app")) {
						AppStore.navigate("/app/main")
					}

					AppStore.setAppLoaded()
					return
				}).catch(async () => {
					await AppStore.logout()
				})
			}

			// Attempt to sign in user via OAuth using 'code' parameter in URI
			var oauthTokenRequestBody = {
				code: AppStore.getURLPeram(window.location.href, "code"),
				state: AppStore.getURLPeram(window.location.href, "state"),
				redirect_uri: process.env.REACT_APP_ROOT_URL
			}

			if (oauthTokenRequestBody.code) {
				await AppStore.loginOAuth(oauthTokenRequestBody)
			}

			if (AppStore.isLoggedIn) return

			// Default to login screen
			AppStore.resetApp()
		}

		loadAuthentication().catch(() => {
			console.log("There was an error when initialising app.")
		})

	}, [AppStore])

	if (!AppStore.appLoaded) return <LinearProgress />

	return (
		<div className="App">
			<Routes>

				<Route path="/" element={<GuestView />} />
				<Route path="/error/:error" element={<ErrorView />} />

				<Route path="/app" element={<AuthenticatedRoutes />}>
					<Route path="main" element={<MainView />} />
					<Route path="main/:folder_uid" element={<MainView />} />
					<Route path="main/:folder_uid/:leafnode_uid" element={<MainView />} />
					<Route path="ui-demo" element={<UiDemoView />} />
					<Route path="*" element={<NotFound />} />
				</Route>
			</Routes>
		</div>
	)
})


function AuthenticatedRoutes () {        

    const { isLoggedIn, resetApp } = store.AppStore
    const params = useParams()

    React.useEffect(() => {
        store.NodeStore.handleUri(params)
        store.AppStore.setPageTitle("AORA")
    }, [params])

    if (!isLoggedIn) return <></>

    return (
        <ErrorBoundary>
            <Header />
            <ViewBanner title={<BreadCrumb />} />
            <Outlet />
        </ErrorBoundary>
    )
}
