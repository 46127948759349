import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import Help from "../../../../../misc/Help";
import { DisplayValue } from "../../../../..";
import { Enquiry } from "../../../../../models/Enquiry";
import { store } from "../../../../../stores/Store";


interface EnquiryStatusIndicatorProps {
    enquiry: Enquiry;
}


export default observer(function EnquiryStatusIndicator({enquiry}: EnquiryStatusIndicatorProps) {
    
    const { AppStore } = store

    if (!enquiry.status) {
        return <></>
    }

    var running = AppStore.stateSubject === enquiry.uid 

    var percentage = 10

    if (enquiry.status === "INTERIM") {
        percentage = 50
    } else if (enquiry.status === "FINAL") {
        percentage = 75
    } else if (enquiry.status === "RERUN_REQUIRED") {
        percentage = 90
    } else if (enquiry.status === "DEFINITIVE" || enquiry.status === "REPORT_DATA_REQUIRED") {
        percentage = 100
    }

    if (!enquiry.valid) {
        percentage -= 10
    }

    // Get outcomes
    var outcomes = {}
    var interimOutcomes = {}
    var definitiveOutcomes = {}
    var statusSection = enquiry.childrenObjects.find(c => c.name === "STATUSES")

    if (statusSection) {
        statusSection.childrenObjects.forEach(status => {
            if (status.lastInterimValue) {
                interimOutcomes[status.code] = status.lastInterimValue
            }
            if (status.lastDefinitiveValue) {
                definitiveOutcomes[status.code] = enquiry.status === "DEFINITIVE" ? status.value : status.lastDefinitiveValue
            }
            if (status.value) {
                outcomes[status.code] = status.value
            }
        })
    }

    return (
        <div className={`enquiry-status-indicator ${running ? "running" : null}`}>
            <div className="status-details">
                <small>
                    {running ? store.AppStore.stateAction : DisplayValue("enquiry_status", enquiry.status)}
                </small>
                {/* <Button disabled={!!AppStore.sessionState} onClick={() => EnquiryStore.progressEnquiry()} className="btn btn-xs">
                    {EnquiryStore.actionLabel(enquiry)}
                </Button> */}
            </div>
            <div className="progress">
                <div className="progress-bar" style={{maxWidth: `${percentage}%`}}></div>
            </div>
            <div className="progress-nodes">
                <Help content={<div className="progress-node reached"></div>}>
                    <small>Enquiry created</small>
                    <span>{format(new Date(enquiry.created), "dd/MM/yyyy")}</span>
                </Help>
                <Help content={<div className={`progress-node ${percentage >= 50 || enquiry.lastInterimOutcomeDt ? "reached" : ""}`}></div>}>
                    <small>Interim outcome</small>
                    <OutcomeList outcomes={interimOutcomes} />
                </Help>
                <Help content={<div className={`progress-node ${percentage >= 100 || enquiry.lastDefinitiveOutcomeDt ? "reached" : ""}`}></div>}>
                    <small>Full outcome</small>
                    {enquiry.status === "DEFINITIVE" ? <OutcomeList outcomes={outcomes} /> : null}
                    {["RERUN_REQUIRED", "REPORT_DATA_REQUIRED"].includes(enquiry.status) 
                        ? <OutcomeList outcomes={definitiveOutcomes} />
                        : null}
                </Help>
            </div>
        </div>
    )
})


function OutcomeList ({outcomes}) {
    return (
        <>
            {Object.keys(outcomes).map(key => (
                <div style={{display: "grid", gridTemplateColumns: "auto 10px 48px", padding: "2px 0"}}>
                    <div>{DisplayValue("nationality", key)}</div>
                    <div></div>
                    <div>{DisplayValue("outcome", outcomes[key])}</div>
                </div>
            ))}
        </>
    )
}