import { Alert, AlertModal } from '../../..'
import { observer } from 'mobx-react-lite'
import { Icons } from '../../../misc/Icons'
import { store } from '../../../stores/Store'
import PanelBody from '../../layout/PanelBody'
import PanelHeader from '../../layout/PanelHeader'
import { Button, IconButton, LinearProgress } from '@mui/material'
import ErrorBoundary from '../../../misc/ErrorBoundary'
import TemplateTable from './TemplateTable'
import TemplateForm from '../../form/questionnaire/TemplateForm'
import Ajax from '../../../Ajax'
import React from 'react'


export default observer(function TemplateTool () {        

    var userAccountUid = store.AppStore.sessionInfo.userAccount?.uid

    return (
        <ErrorBoundary>
            <div className="panel panel-dark">
                <PanelHeader>
                    <div className="header">
                        <h2>Questionnaire Templates</h2>
                        
                        <Button
                            className="btn btn-sm"
                            variant="contained"
                            onClick={() => AlertModal({body: <TemplateCreate />, width: "900px"})}>
                            Create Template
                        </Button>
                    </div>
                    
                    <IconButton style={{marginLeft: "auto"}} size="small" onClick={() => store.ToolStore.setTool(null)}><Icons.X /></IconButton>
                </PanelHeader>
                
                <PanelBody>
                    <TemplateTable onRowClick={(key) => AlertModal({body: <TemplateEditForm templateKey={key} />, width: "800px"})} />
                </PanelBody>
            </div>
        </ErrorBoundary>
    )
})


function TemplateCreate () {
    return (
        <TemplateForm 
            title="Create Template"
            onSubmit={async (values) => {
                await Ajax.QuestionnaireTemplate.Create(values).then(() => {
                    store.AlertStore.Close()
                    store.QuestionnaireStore.getTemplates()
                }).catch((response) => {
                    Alert({message: response.data?.detail ? response.data?.detail : "Couldn't create template"})
                })
            }} />
    )
}



function TemplateEditForm ({templateKey}) {

    const [template, setTemplate] = React.useState(null)

    React.useEffect(() => {
        Ajax.QuestionnaireTemplate.Get(templateKey).then((response) => {
            setTemplate(response.data)
        }).catch((response) => {
            Alert({message: response.data?.detail ? response.data.detail : "Failed to get template details"})
        })
    }, [])

    if (!template) return <LinearProgress />

    return (
        <TemplateForm 
            title="Template Details"
            template={template}
            onSubmit={async (values) => {
                await Ajax.QuestionnaireTemplate.Update(values).then(() => {
                    store.AlertStore.Close()
                    store.QuestionnaireStore.getTemplates()
                }).catch((response) => {
                    Alert({message: response.data?.detail ? response.data?.detail : "Couldn't create template"})
                })
            }} />
    )
}