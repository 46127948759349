import { format } from "date-fns"
import { Button, CircularProgress } from "@mui/material"
import { observer } from "mobx-react-lite";
import { DisplayValue } from "../../../../..";
import { Icons } from "../../../../../misc/Icons";
import TruncText from "../../../../../misc/TruncText";
import { store } from "../../../../../stores/Store";
import Help from "../../../../../misc/Help";
import { Report } from "../../../../../models/Report";
import { Theme } from "../../../../../misc/Theme";
import NodeMenus from "../../../../../misc/NodeMenus";


interface ReportProps {
    report: Report;
}


export default observer(function ReportItem ({report}: ReportProps) {

    var enquiry = store.EnquiryStore.enquiry

    const StatusCell = () => {

        var warning: string | false = false

        if (!report.valid && report.fileStatus !== "R") {
            warning = "This report is not valid."
        } else if (report.fileStatus === "X") {
            warning = "This report generated with errors."
        } else if (report.fileStatus === "F") {
            warning = "This report failed to generate."
        }
        
        if (warning) {
            return (
                <Help content={(
                    <Icons.Warning color={report.fileStatus === "X" ? "secondary" : "error"} />
                )}>
                    {warning}
                </Help>
            )
        }
        
        return <Icons.Report style={{color: Theme.palette.primary.main}} />
    }

    const OutcomeCell = () => {
        if (report.fileStatus === "R") {
            return <CircularProgress style={{width: "10px", height: "10px"}} />
        } else if (report.outcome?.includes("Y")) {
            return <div style={{color: Theme.palette.success.main, fontWeight: 600}}>{DisplayValue("outcome", report.outcome)}</div>
        } else if (report.outcome?.includes("N")) {
            return <div style={{color: Theme.palette.error.main, fontWeight: 600}}>{DisplayValue("outcome", report.outcome)}</div>
        }
        return <></>
    }

    return (
        <div className="section-row report-row">
            <div className="section-cell">
                <StatusCell />
            </div>

            <div className="section-cell">
                <TruncText text={DisplayValue("report_type", report.reportType)} />
            </div>
            
            <div className="section-cell">
                <Help content={format(new Date(report.requestedAt), "dd/MM/yyyy")}>
                    <h3>{report.uid}</h3>
                    
                    <div style={{display: "grid", gridTemplateColumns: "80px 100px", margin: "5px 0"}}>
                        <span>Requested:</span>
                        <span>{format(new Date(report.requestedAt), "dd/MM/yyyy HH:mm")}</span>
                        
                        <span>Generated:</span>
                        <span>
                            {report.fileStatus === "R" && enquiry.status === "DEFINITIVE" 
                                ? <CircularProgress style={{width: "10px", height: "10px"}} />
                                : format(new Date(report.generatedAt), "dd/MM/yyyy HH:mm")}
                        </span>
                    </div>
                    
                </Help>
            </div>

            <div className="section-cell">
                <OutcomeCell />
            </div>
            
            <div className="section-cell">
                {store.AppStore.checkJwtRole("sysuser") ? (
                    <div style={{margin: "0 5px 0 auto"}}>
                        <NodeMenus node={report} />
                    </div>
                ) : (
                    <Button 
                        color="success"
                        variant="contained"
                        style={{padding: "4px 4px"}}
                        className="btn btn-lowercase" 
                        disabled={!!(store.AppStore.sessionState || ["R", "F"].includes(report.fileStatus))} 
                        onClick={() => store.ReportStore.download(report)}>
                        Download
                    </Button>
                )}
            </div>
        </div>
    )
})