import {Column, Table, AutoSizer} from 'react-virtualized';
import { DisplayValue } from '../../..';
import { store } from '../../../stores/Store';
import { Button, LinearProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { format, isValid } from 'date-fns';
import React from 'react';
import { Icons } from '../../../misc/Icons';


interface QuestionnaireTableProps {
    width?: string;
    height?: string;
    onRowClick?: Function;
    onCheckboxClick?: Function;
    size?: "sm" | "md";
    highlightedOptions?: string[];
    selectedOptions?: string[];
}


export default observer(function QuestionnaireTable ({
    onRowClick = (uid: string) => {},
    height = "400px",
    size = "md"
}: QuestionnaireTableProps) {

    const { loading, templates, getTemplates } = store.QuestionnaireStore
    const minWidth = 390
    var rowHeight = size === "md" ? 40 : 30

    React.useEffect(() => {
        getTemplates()
    }, [])

    const formatDate = (dateString, placeholder = "") => {
        if (!dateString) return <span className="font-grey">{placeholder}</span>
        var date = new Date(dateString)
        return isValid(date) ? format(date, "dd/MM/yyyy H:mm") : <span className="font-grey">{placeholder}</span>
    }

    if (templates === null) return <LinearProgress />

    var orderedTemplates = [...templates].sort((a, b) => b.insertedAt.localeCompare(a.insertedAt))

    return (
        <div className={`rv-table-wrapper rv-table-${size}`} style={{minHeight: height}}>
            <AutoSizer>
                {({height, width}) => (
                    <Table
                        width={width < minWidth ? minWidth : width}
                        height={height}
                        headerHeight={rowHeight}
                        rowHeight={rowHeight}
                        rowCount={orderedTemplates?.length}
                        rowGetter={({index}) => orderedTemplates[index]}
                        onRowClick={({event, index, rowData}) => {
                            // Don't do anything if click was on a input field
                            if (event.target.nodeName === "DIV") {
                                onRowClick(rowData.key)
                            }
                        }} >
                        <Column
                            dataKey=""
                            width={200}
                            headerRenderer={() => <>Key</>}
                            cellRenderer={({rowData}) => (
                                <>{rowData.key}</>
                            )} />
                        
                        <Column
                            dataKey=""
                            width={250}
                            headerRenderer={() => <>Display name</>}
                            cellRenderer={({rowData}) => (
                                <>{rowData.displayName}</>
                            )} />
                        
                        <Column
                            dataKey=""
                            width={120}
                            headerRenderer={() => <>Advisor Uid</>}
                            cellRenderer={({rowData}) => {
                                return rowData.advisorUid ? <span>{rowData.advisorUid}</span> : <span className="font-grey">Global</span>
                            }} />

                        <Column
                            dataKey=""
                            width={80}
                            headerRenderer={() => <>Active</>}
                            cellRenderer={({rowData}) => {
                                return rowData.active ? <span>Active</span> : <span className="font-grey">Disabled</span>
                            }} />

                        <Column
                            dataKey=""
                            width={120}
                            headerRenderer={() => <>Inserted at</>}
                            cellRenderer={({rowData}) => (
                                <>{formatDate(rowData.insertedAt)}</>
                            )} />

                        <Column dataKey="" width={35}   headerRenderer={(rowData) => <></>} cellRenderer={(data) => <div style={{marginLeft: "auto"}}></div>} />
                    </Table>
                )}
            </AutoSizer>

            {loading ? <LinearProgress color="primary" style={{marginTop: `${rowHeight}px`}} /> : null}
        </div>
    )
})