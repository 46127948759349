// @ts-nocheck
import Ajax from '../Ajax'
import { add } from 'date-fns'
import { store } from './Store'
import { Alert, AlertModal } from '..'
import { makeAutoObservable, reaction } from 'mobx'
import { StateChange } from '../models/StateChange'
import { InformationRequest } from '../models/InformationRequest'
import PopupRequestForm from '../components/form/PopupRequestForm'
import signalR, { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr"
import EnquiryOutcomeModal from '../components/views/main/detail/enquiry/EnquiryOutcomeModal'


export class ConnectionStore {

    hubConnection: HubConnection | null = null;
    connectionState: string | null = null;
    waiting: string[] = [];
    testCount: number = 10;
    testsVerified: number = 0;
    testComplete: boolean = true;
    lastTest: Date = null;
    transportTypes = {
        "0": "Automatic",
        1: "WebSockets (recommened)",
        2: "Server Sent Events",
        4: "Long Polling (not recommended)"
    }
    transportType: "0" | 1 | 2 | 4 = "0"

    constructor() {
        makeAutoObservable(this)

        reaction(() => this.hubConnection?.state, state => {
            this.connectionState = state
        })

        window.onmouseover = async () => {
            // Test when user shows activity, limited to once every 30 seconds.
            if (store.AppStore.isLoggedIn && this.testComplete && (!this.lastTest || add(this.lastTest, {seconds: 60}) < new Date())) {
                console.log("Testing connection")
                
                this.lastTest = new Date()
                var intialTestsVerified = this.testsVerified
                
                this.testComplete = false
                
                await Ajax.Message.Test(1).then(() => {
                    this.testComplete = true
                }).catch(() => {
                    console.log("Connection test failed")
                })

                setTimeout(async () => {
                    // If no test websockets messages are received
                    if (intialTestsVerified === this.testsVerified) {
                        console.log("Attempting to reconnect, test message was not received.")
                        await this.stopHubConnection()
                        await this.createHubConnection()
                    }
                    this.testsVerified = 0
                }, 2000)

                // Make this request to stop the ND server from timing out.
                // A user can become inactive on the ND while still being active on 
                // Other features like questionnaires and automations.
                if (store.AppStore.sessionInfo?.userUid) {
                    Ajax.Node.Get(store.AppStore.sessionInfo.userUid).catch(() => {
                        console.log("Failed to get user when testing connection")
                    })
                }
            }
        }
    }

    setTransportType = async (transportTypeNumber: number) => {
        await this.stopHubConnection()
        this.transportType = transportTypeNumber
        await this.createHubConnection()
    }

    routeMessages = () => {
        this.hubConnection.on("InformationRequestIntercepted", (iri: any) =>                    store.QuestionnaireStore.handleInformationRequestIntercepted(iri))
        this.hubConnection.on("DataSourceNotification", (message: any) =>                       store.QuestionnaireStore.handleDataSourceChangeNotification(message))
        this.hubConnection.on("InformationRequest", (message: InformationRequest) =>            store.QuestionStore.receiveInformationRequest(message))
        this.hubConnection.on("EnquiryStatusNotification", (message: any) =>                    handleEnquiryStatusNotification(message))
        // this.hubConnection.on("HighlightNotification", (message: any) =>                        console.log(message))
        this.hubConnection.on("DisconnectNotification", (message: string) =>                    this.handleDisconnect(message))
        this.hubConnection.on("CloseConnection", (message: string = "") =>                      this.stopHubConnection())
        this.hubConnection.on("StateChange", (stateChange: StateChange) =>                      store.AppStore.setSessionState(stateChange))
        this.hubConnection.on("AutomationNotification", (message: any) =>                       store.AutomationStore.handleAutomationNotification(message))
        this.hubConnection.on("PopupRequest", (popupRequest: any) =>                            handlePopupRequest(popupRequest))
        this.hubConnection.on("RunInfo", (message: string) =>                                   store.QuestionStore.handleRunInfo(message))
        this.hubConnection.on("Notify", (message: string) =>                                    Alert({ message: message }))
        this.hubConnection.on("Test", (message: string) =>                                      {this.testsVerified += 1})
    }

    handleDisconnect = (message) => {
        console.log("DisconnectNotification", message)
        store.AppStore.resetApp()
        Alert({
            message: "You have been disconnected", 
            actions: message?.length > 5 
                ? [{label: "More info", action: () => AlertModal({body: message})}] 
                : []
        })
    }

    testConnection = async (testCount = this.testCount) => {

        if (!this.testComplete) return

        this.testsVerified = 0
        this.testComplete = false

        await Ajax.Message.Test(testCount).then(() => {
            this.testComplete = true
        }).catch(() => {
            console.log("Connection test failed")
        })
    }

    createHubConnection = async (first_try = true) => {

        var options = { accessTokenFactory: () => store.AppStore.token! }

        if (this.transportType !== "0") { options["transport"] = this.transportType }

        this.hubConnection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_SIGNALR_URL}`, options)
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();

        this.routeMessages()

        return await this.hubConnection.start().then(() => {
            this.connectionState = "Connected"
        }).catch(async (error) => {

            console.log("Error when connecting via websockets", error)

            if (!store.AppStore.isLoggedIn) return

            // If authorisation fails twice, log out
            if (first_try === false) {
                Alert({message: "ConnectionStore: Failed to connect"})
                store.AppStore.resetApp()
                return 
            }

            // if (error?.statusCode === 401) {
                
            //     // Attempt to refresh auth token
            //     await Ajax.Session.RefreshToken().then(() => {
            //         Alert({message: "ConnectionStore: New token saved"})
            //         return this.createHubConnection(false) // When a new token is acquired, try again
            //     }).catch(() => {
            //         console.log("ConnectionStore failed to refresh token")
            //     })
            
            // } else { 
                
            //     // Handle non-auth errors with a basic message
            //     console.log("SignalR connection error", error, error.statusCode, typeof error.statusCode, error.statusCode, Object.keys(error), Object.values(error))

            //     Alert({message: "Faulty connection", persist: true, closable: true, actions: [
            //         {label: "See more", action: () => AlertModal({body: <p>{error?.message}<br/>{error?.stack}</p>, code: true})}
            //     ]})
            //     }
            // }
        })
    }

    stopHubConnection = async () =>
        await this.hubConnection?.stop().then(() => {
            this.hubConnection = null
        }).catch((error) => 
            console.log("Error stopping connection", error)
        )
}


const handleEnquiryStatusNotification = async (message) => {

    if (message?.status === "INTERIM" || message?.status === "DEFINITIVE") {
        AlertModal({id: "outcome", body: <EnquiryOutcomeModal />, size: "lg"})
        
        setTimeout(() => {
            if (store.EnquiryStore.enquiry?.uid !== message.enquiryUid) {
                store.NodeStore.navigateNode(message.enquiryUid)
            }
        }, 5000)
    }
}

const handlePopupRequest = (pr: any) => {
    console.log("Popup request", pr)
    AlertModal({id: pr.messageId, requireAction: true, body: <PopupRequestForm pr={pr} />})
}