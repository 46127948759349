import React from "react"
import { Alert } from "../../.."
import { store } from "../../../stores/Store"
import AutomationConfirmFormSection from "./AutomationConfirmFormSection"
import AutomationQuestionnaireSelectFormSection from "./AutomationQuestionnaireSection"


export default function CreateAutomationForm ({automationOption}) {

    const [automationData, setAutomationData] = React.useState(null)

    const submit = (values) => {
        var automationDataToSend = {...automationData, ...values}
        setAutomationData(automationDataToSend)
        
        store.AutomationStore.run(automationOption.id, JSON.stringify(automationDataToSend)).then(() => {
            store.AlertStore.Close()
        }).catch(() => {
            Alert({message: "Failed to run automation"})
        })
    }

    return (
        <div className="form">
            <AutomationQuestionnaireSelectFormSection 
                hidden={automationData} 
                automationOption={automationOption}
                onCancel={() => store.AlertStore.Close()}
                onSubmit={(data) => setAutomationData(data)} />


            <AutomationConfirmFormSection
                hidden={!automationData} 
                automationData={automationData}
                automationOption={automationOption}
                onCancel={() => setAutomationData(null)}
                onSubmit={(data) => submit(data)} />
        </div>
    )
}