import { Button } from "@mui/material"
import React from "react"
import QuestionnaireSelectMultipleField from "../../questionnaire/QuestionnaireSelectMulitpleField"
import Ajax from "../../../../Ajax"
import { Alert } from "../../../.."
import QuestionnaireDetails from "../../../views/questionnaire_mgmt/QuestionnaireDetails"
import { Icons } from "../../../../misc/Icons"


export default function EnquiryDataSourcesFormSection ({
    hidden,
    onCancel = () => {},
    onSubmit = (data) => {},
}) {

    const [questionnaireUidArray, setQuestionnaireUidArray] = React.useState([])
    const [questionnairePreview, setQuestionnairePreview] = React.useState(null)

    const previewQuestionnaire = async (uid) => {
        await Ajax.Questionnaire.Get(uid).then(response => {
            setQuestionnairePreview(response.data)
        }).catch(response => {
            Alert({message: "Failed to get questionnaire"})
        })
    }

    if (hidden) return <></>

    return (
        <div>
            <h2>Create Enquiry<span style={{color: "grey", fontWeight: 400}}> - Data Sources</span></h2>

            {questionnairePreview ? (
                <>
                    <QuestionnaireDetails questionnaire={questionnairePreview} />
                    <div className="form-buttons">
                        <Button
                            className="btn btn-sm"
                            variant="contained"
                            color="success"
                            onClick={() => setQuestionnairePreview(null)}>
                            <Icons.ChevronLeft /> Exit preview
                        </Button>
                    </div>
                </>
            ) : null}

            <div style={{display: !!questionnairePreview ? "none" : "block"}}>
                <QuestionnaireSelectMultipleField
                    value={questionnaireUidArray}
                    onChange={(uidArray) => setQuestionnaireUidArray(uidArray)}
                    onRowClick={(questionnaireUid) => {
                        previewQuestionnaire(questionnaireUid)
                    }} />

                <div className="form-buttons">
                    <Button
                        className="btn btn-sm"
                        onClick={() => onCancel()}>
                        Back
                    </Button>

                    <Button
                        className="btn btn-sm"
                        variant="contained"
                        color="success"
                        onClick={() => onSubmit(questionnaireUidArray)}>
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}


